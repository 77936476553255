import { Component, OnInit, ViewChild } from '@angular/core';
import csv2json from 'csvjson-csv2json/csv2json';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import config from "devextreme/core/config";
// import XLSX from 'xlsx-style/dist/xlsx.full.min';
import XLSX from 'xlsx-style/dist/xlsx';

import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as InvoiceActions from '../../../database/action/InvoiceAction';
import { Observable, Subscription } from 'rxjs';
import { InvoiceState } from '../../../database/state/InvoiceState';
import { InvoiceModel } from '../../../database/models/invoiceModel';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-contribution-upload',
  templateUrl: './contribution-upload.component.html',
  styleUrls: ['./contribution-upload.component.scss'],
})
export class ContributionUploadComponent extends SharedClassComponent
  implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  showLoadingDialog = false;
  prefixText = 'Correct Name is: ';
  hideInfoAlert = true;
  invoiceForm: FormGroup;
  lastContributionDatasetForm: FormGroup;
  invoiceCreateEndPoint = 'portal/request';
  selectedRowIndex = -1;
  isFileValid = false;
  yearPattern = '^(19|20)\\d{2}$';
  monthPattern = '/^(0[1-9]|1[012])$/';
  employerContributionAmt = 0;
  memberContributionAmt = 0;
  currrentEmployerNumber: any;
  userGroup: any;
  isMultipleContributionMode = false;
  value: any[] = [];
  title = 'Create Invoice For Employees Contributions';
  loadExistingListEndpointCall = 'employer/employees';
  createInvoiceEndpointCall = 'invoice/create';
  validateMemberNumberEndPoint = 'users/verify';
  validateEmployerNumberEndPoint = '';
  uploadFailedMessage = '';
  openFileUploadDialog = false;
  employeeUploadListDataSource: any = [];
  employeeUploadListDataSourceN: any = [];
  hideEmployeeListDataGrid = true;
  hideEmployeeFetchedDataListDataGrid = true;
  hideEmployeeListManualEntrance = true;
  hideEmployeeUploadForm = true;
  hideDataUploadControls = false;
  hideInitialInvoiceDetails = false;
  isUploadFileMethodSelected = false;
  hideBackButton = true;
  steps = 0;
  months = [];
  years: any;
  curDate = new Date();
  userDetails: any;
  currentMonth = this.curDate.getMonth() +  1;
  currentYear = this.curDate.getFullYear();
  invoiceDataset: Observable<InvoiceState>;
  invoiceSubscription: Subscription;
  invoiceData: InvoiceModel[] = [];
  invoiceError: Error = null;
  buttonText = 'Create Invoice';
  iconText = 'save';
  showYearAndMonthColumn = true;
  batchContributionData = [];
  isContributionBatchRequest = false;
  isAddNewContributionToAnyExistingInvoice = false;
  invoiceIdToAddContribution;
  disableContributionType = false;
  lastContributionsDataSet = [];
  currency = [{ name: "TZS" }, { name: "USD" }];
  selectedContributionMonths = [];
  selectedContributionYear;
  selectedContributionType;
  descriptionString = '';
  monthInText = [];
  hideLoadMembersButton = true;
  showAlertDialog = false;
  showAlertDialogN = false;
  showAlertDialogNno = false;
  showWarningDialog = false;
  alertReason = '';
  alertReasonN = '';
  isIndividualSelected = false

  showUpdate = false
  UpdatedArray =[]


  lastContributionTotalAmount = 0;
  currentContributionTotalAmount = 0;
  continueWithWarning = false;

  customerDetails = [];
  uncontributedPeriod = [];
  yearsMonths = [];
  setProceed = false
  setProceedN = false
  contributionAmount =0


  monthSelected = []
  LimitedMonthGov =[]
  compensationZeroGov = false
  contributionTypeValue : any
  cMonthTypeSelected = false
  isNewRowInserted = false
  

  isEmployerGov = false
  isContributionMonthSelected = false
  myArrays: any = []

  contributionYear:any = new Date().getFullYear()

  user = this.authService.getUserDetails();

  showDialog = false;

  contributionType = [
    {
      id: 1,
      text: 'Contributions'
    },
    {
      id: 2,
      text: 'Arrears'
    },
    {
      id: 3,
      text: 'Adjustments'
    },
    {
      id: 4,
      text: 'Compensation'
    }
  ];

  accountName: any;
  hideContributionMonthGap = true;
  contributionMonthGapMessage = '';

  sivs = {
    requestType: "SETTING_LIST"
  }
  thresholdAmount = 10000;
  employerPercent = 13;
  memberPercent = 7;
  compensationPercent=1
  contributionAmountDiffInPercent = 80;
  allowAmountContributionGap = 1;
  settingsEndPoint = 'settings';
  isMemberNamesMatch: boolean = true;
  isMemberNumberValid: boolean = true;

  showDownloadTutorialDialog = false;

  tab_paneldata: any = [
    {
    ID: 1,
    icon: 'fa fa-check-circle',
    name: 'Contribution Period Details',
   },
   {
    ID: 2,
    icon: 'fa fa-calendar',
    name: 'Contributed Members',
   }
];
  memberSalary = 0;
  memberContribution = 0;
  employerContribution = 0;
  amountContributed = 0;
  amountContributedG = 0;
  compensation =0;
  totalMembers = 0;
  totalMembersWithoutNames = 0;
  totalMembersWithoutNumbers = 0;
  totalMembersWithoutNamesAndNumber = 0;
  totalMembersWithInvalidNumbers = 0;
  totalMembersWithNameMismatch = 0;
  totalDuplicateMemberNumbers = 0;
  totalDuplicateMembers = 0;
  totalContributionMonths = 0;
  showCalculatingValueBanner = false;
  showCalculatingDatasourceChanges = false;


  ngOnInit() {
     //
    //this.isEmployerGov = false
    //this.compensationZeroGov = false
    //this.contributionTypeValue =null
    this.employeeUploadListDataSource.splice(0)
    sessionStorage.removeItem("employeeUploadListDataSource")
    this.isNewRowInserted = false

    config({
      defaultCurrency: 'TZS'
    });
    this.lastContributionDatasetForm = new FormGroup({
      contributionPeriod: new FormControl(null, Validators.compose([Validators.required]))
    });
    
    // this.spinner.show();
    this.loadSettings();

    //.sectorId)

    if(this.authService.getUserDetails().sectorId == 1)
    {
      this.isEmployerGov = true
    }

    this.currrentEmployerNumber = this.authService.getUserDetails().linkId;
    this.userGroup = this.authService.getUserDetails().userGroup;
    //this.userGroup = 'INDIVIDUAL_CONTRIBUTOR'
    this.accountName = this.authService.getUserDetails().accountName;

    if (`${this.userGroup}`.match('EMPLOYER')) {
      this.getUncontributedPeriods();
      this.getCaihSettings();
      //this.invoiceForm.get("contributionMonth").reset()
      //this.invoiceForm.get("contributionType").reset()
    }
     
     // INDIVIDUAL_CONTRIBUTOR
    // INDIVIDUAL_CONTRIBUTOR
    //EMPLOYER
    //INDIVIDUAL_CONTRIBUTOR

    if (`${this.userGroup}`.match('INDIVIDUAL_CONTRIBUTOR')) {
      this.title = 'Individual Contribution';
      this.contributionType = [
        {
          id: 1,
          text: 'Contributions'
        }
      ];
      this.hideDataUploadControls = true;
      
      const dt = {};
      this.spinner.show();
      this.utilities.postServiceCall(dt, this.invoiceCreateEndPoint).subscribe(res => {
        const serverRes = res.json();
        if (serverRes.code == 2000) {
          this.employeeUploadListDataSource = [{
            id: 1,
            memberNames: this.accountName,
            memberSalary: serverRes.data.salaryBeforeTax,
            employerNumber: serverRes.data.employerNumber,
            memberNumber: serverRes.data.memberNumber,
            memberContribution: Math.round(((this.memberPercent / 100) * +serverRes.data.salaryBeforeTax)),
            employerContribution: Math.round(((this.employerPercent / 100) * +serverRes.data.salaryBeforeTax)),
            compensation:0,
            amountContributed: Math.round((((this.memberPercent / 100) * +serverRes.data.salaryBeforeTax) + ((this.employerPercent / 100) * +serverRes.data.salaryBeforeTax))),
            totalContributionsAmt: Math.round((((this.memberPercent / 100) * +serverRes.data.salaryBeforeTax) + ((this.employerPercent / 100) * +serverRes.data.salaryBeforeTax))),
            amountContributedG: Math.round(((this.memberPercent / 100) *serverRes.data.salaryBeforeTax) + ((this.employerPercent / 100) * serverRes.data.salaryBeforeTax))
          }];
        } else {
          this.employeeUploadListDataSource = [{
            id: 1,
            memberNames: this.accountName,
            memberSalary: 0,
            employerNumber: this.currrentEmployerNumber,
            memberNumber: this.currrentEmployerNumber,
            memberContribution: 0,
            employerContribution: 0,
            amountContributed: 0,
            compensation:0,
            totalContributionsAmt: 0,
            amountContributedG: 0
          }];
        }
        this.spinner.hide();
      }, err => {
        this.employeeUploadListDataSource = [{
          id: 1,
          memberNames: this.accountName,
          memberSalary: 0,
          employerNumber: this.currrentEmployerNumber,
          memberNumber: this.currrentEmployerNumber,
          memberContribution: 0,
          employerContribution: 0,
          amountContributed: 0,
          compensation:0,
          totalContributionsAmt: 0,
          amountContributedG: 0
        }];
        this.spinner.hide();
      });
    }

    // this.onLoadPreviousContributionData();
    this.route.queryParams.subscribe((params) => {
      this.isAddNewContributionToAnyExistingInvoice = params.addContribution;
      this.invoiceIdToAddContribution = params.id;
    });
    if (this.isAddNewContributionToAnyExistingInvoice) {
        this.disableContributionType = true;
    }
     
    this.spinner.hide();
    this.userDetails = this.authService.getUserDetails();
    
    //
    // this.months = this.contributionService.getMonthsDataset();
    for (let index = 0; index < this.contributionService.getMonthsDataset().length; index++) {
      // if (index <= 5) {
        this.months.push(this.contributionService.getMonthsDataset()[index]);
      // }
    }

    this.years = this.contributionService.getYears();

    this.invoiceForm = new FormGroup({
      contributionYear: new FormControl(this.currentYear, Validators.compose([Validators.required, Validators.maxLength(4), Validators.minLength(4)])),
      contributionMonth: new FormControl(null , Validators.compose([Validators.required])),
      contributionType: new FormControl(1, Validators.compose([Validators.required])),
      currency: new FormControl("TZS", Validators.compose([Validators.required])),
      description: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(300)])),
    });

    this.contributionTypeValue =null

    this.invoiceForm.get("contributionMonth").reset()
    //this.invoiceForm.get("contributionType").reset() 
  }

  getCaihSettings() {
    const data = {
      requestType: 'EMPLOYER_GET',
      employerRef: this.authService.getUserDetails().linkId
    }
    this.spinner.show();
    this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
      const serveRes = res.json();
      if (serveRes.code == 2000) {
          sessionStorage.removeItem(AppSettings.CAIH);
          sessionStorage.setItem(AppSettings.CAIH, serveRes.data.multInstallments);
          // this.caih = serveRes.data.multInstallments;
        this.caih = '0';
      } else {
        this.caih = '0';
      }
      this.spinner.hide();
    }, err => {
      
      this.spinner.hide();
    });
  }

  loadSettings() {
    this.utilities.postServiceCall(this.sivs, 'backend/request').subscribe(res => {
      const serveRes = res.json();
      if (serveRes.code == 2000) {
        for(const iterator of serveRes.data) {
          if (iterator.name == 'thresholdAmount') {
            this.thresholdAmount = +iterator.value;
          }
          if (iterator.name == 'CONTRIBUTION_AMOUNT_GAP_IN_PERCENT') {
            this.contributionAmountDiffInPercent = +iterator.value;
          }
          if (iterator.name == 'EMPLOYER_CONTRIBUTION_PERCENT') {
            this.employerPercent = +iterator.value;
            this.compensationPercent = 1
          }
          if (iterator.name == 'MEMBER_CONTRIBUTION_PERCENT') {
            this.memberPercent = +iterator.value;
          }
          if (iterator.name == 'CONTRIBUTION_AMOUNT_GAP_ALLOWED') {
            this.allowAmountContributionGap = +iterator.value;
          }
        }
      } else {
        // reset to normal
        this.thresholdAmount = 10000;
        this.employerPercent = 13;
        this.memberPercent = 7;
        this.compensationPercent = 1;
        this.contributionAmountDiffInPercent = 80;
        this.allowAmountContributionGap = 1;

        // this.toastr.error(serveRes.message);
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.thresholdAmount = 10000;
      this.employerPercent = 13;
      this.memberPercent = 7;
      this.compensationPercent =1
      this.contributionAmountDiffInPercent = 80;
      this.allowAmountContributionGap = 1;
    });
  }

  //for member number
  hideDialog() {
    this.showAlertDialogN = false;
  }

  hideAlertDialog()
  {
    this.showAlertDialog = false;
  }

  rectifyMembernames()
  {
    this.dataGrid.instance.filter(['Name Matched', '=', false]);
    this.showAlertDialogN = false
  }
 

  proceedInvoiceCreation()
  {
    const result = confirm(
      'Are you sure you want to proceed with some members names mismatch?',
      'Confirm'
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.setProceed = true
        this.postInvoice()
        this.showAlertDialogN =false
      }
    });
     
  }

  hideDialogNno()
  {
    this.showAlertDialogNno = false
  }

  //for member names

  
  rectifyMembernumber()
  {
    this.dataGrid.instance.filter(['Valid Number', '=', false]);
    this.showAlertDialogNno = false
    //this.dataGrid.instance.filter(['Name mismatch','=',false])
  }

  proceedInvoiceCreationN()
  {
    const result = confirm(
      'Are you sure you want to proceed with some members number mismatch?',
      'Confirm'
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.setProceedN = true
        this.postInvoice()
        this.showAlertDialogNno =false
      }
    });
     
  }

  hideDialogN()
  {
    this.showAlertDialogN = false
  }


  postInvoice() {
    if (this.employeeUploadListDataSource.length < 1) {
      notify('No members\' contributions data has been provided', 'error', 6000);
      //notify("Warning message", { position: "center", direction: "up-push" });
      notify
      
      return;
    }
    if(this.employeeUploadListDataSource[0].memberSalary == 0 || this.employeeUploadListDataSource[0].memberSalary == null || this.employeeUploadListDataSource[0].memberSalary == undefined) {
      notify('Member salary can not be less or equal to zero', 'error', 6000);
      return;
    }

    if (this.invoiceForm.invalid) {
      notify('Contribution month, year or description can not be empty!', 'error', 6000);
      return;
    }

    if (this.employeeUploadListDataSource.length <= 0) {
      notify('Members Contribution data required!', 'error', 6000);
      return;
    }

    if (this.invoiceForm.invalid) {
      notify('Contribution month, year or description can not be empty!', 'error', 6000);
   return;
   }

   

   //Checking names
   if(this.setProceed == false){
    if(this.totalMembersWithNameMismatch ){
      
      this.showAlertDialogN = true;
      this.alertReasonN = "Some member names  mismatch please correct";
      //const mismatchData=this.employeeUploadListDataSource.filter((res) => res.isMemberNamesValid == false)
    
      //this.employeeUploadListDataSource.push(mismatchData)
      //this.dataGrid.instance.filter(['Name Matched', '=', false]);
      this.spinner.hide();
      return;
     }
   }

      //Checking number
      // if(this.setProceedN == false){
      //   if(this.totalMembersWithInvalidNumbers ){
          
      //     this.showAlertDialogNno = true;
      //     this.alertReasonN = "Some member number  mismatch please correct";
      //     //const mismatchData=this.employeeUploadListDataSource.filter((res) => res.isMemberNamesValid == false)
        
      //     //this.employeeUploadListDataSource.push(mismatchData)
      //     //this.dataGrid.instance.filter(['Name Matched', '=', false]);
      //     this.spinner.hide();
      //     return;
      //    }
      //  }

   

      let errorMsgGrp = "";
      let isAllVarid = true;
      for (let member of this.employeeUploadListDataSource) {
        if (!member.isMemberNumberValid) {
          errorMsgGrp = `Invalid Details member Number is not Valid (${member.memberNames})`;
  
          isAllVarid = false;
        }
      }
  
      if (!isAllVarid) {
        notify(errorMsgGrp, "error", 6000);  
        return;
      }


    // check if there is uncontributed period
    const contributionData = [];
    const selectedContributionMonths = this.invoiceForm.get('contributionMonth').value;
    this.spinner.show();
    let amount = 0;
    const membersData = [];

    // memberId: this.employeeUploadListDataSource[i].memberId,
              // tslint:disable-next-line: prefer-for-of
    //CHECK CORRECTINESS OF INFORMATIONS 
    for (let i = 0; i < this.employeeUploadListDataSource.length; i++) {

      if((`${this.employeeUploadListDataSource[i].memberNames}`.replace(/\s/g,'') == '' ||
          this.employeeUploadListDataSource[i].memberNames === undefined )&&
         (this.employeeUploadListDataSource[i].memberNumber === undefined ||
          this.employeeUploadListDataSource[i].memberNumber === null ||
           `${this.employeeUploadListDataSource[i].memberNumber}`.replace(/\s/g,'') == '')) {
          this.showAlertDialog = true;
          this.alertReason = `One of the member entry is missing both member number and member names.`;
          this.spinner.hide();
          
          return;
      } else {
        
      }

      //

      if(this.contributionTypeValue == 4)
      {
        amount += +this.employeeUploadListDataSource[i].compensation.toFixed(2);
      }
      else{
        amount += +this.employeeUploadListDataSource[i].amountContributed.toFixed(2) +  +this.employeeUploadListDataSource[i].compensation.toFixed(2);
     
      }

          //amount += this.employeeUploadListDataSource[i].amountContributed;
          //CREATE INDVIDUAL CONTRIBUTIONS OBJECT DATASOURCE
          if(this.employeeUploadListDataSource[i].memberNumber === undefined || `${this.employeeUploadListDataSource[i].memberNumber}`.trim() == '') {
              membersData.push({
              a: this.employeeUploadListDataSource[i].memberNames,
              c: +this.employeeUploadListDataSource[i].memberSalary,
              d: '',
              g:  +this.employeeUploadListDataSource[i].amountContributed.toFixed(2),
              m: +this.employeeUploadListDataSource[i].compensation.toFixed(2)
          });
          } else {
        membersData.push({
          a: this.employeeUploadListDataSource[i].memberNames,
          c: +this.employeeUploadListDataSource[i].memberSalary,
          d: this.employeeUploadListDataSource[i].memberNumber,
          g: +this.employeeUploadListDataSource[i].amountContributed.toFixed(2),
          m: +this.employeeUploadListDataSource[i].compensation.toFixed(2)
         });
          }
        }
       
        
    // tslint:disable-next-line: prefer-for-of
    //CREATE CONTRIBUTION POSTING OBJECT
    for(let index = 0; index < selectedContributionMonths.length; index++) {
          contributionData.push({
          contributionYear: this.invoiceForm.get('contributionYear').value,
          contributionMonth: selectedContributionMonths[index],
          entryType: this.invoiceForm.get('contributionType').value,
          totalAmount: amount.toFixed(2), 
          narration: `Members contribution for ${this.contributionService.getMonthsDataset()[(selectedContributionMonths[index] - 1)].text} ${this.invoiceForm.get('contributionYear').value}`,
          individualContributions: membersData 
        });
      }

      //
      //console.log()
      // check if selected month and year already contributed.
      const yearExcluded = [];
      const monthExcluded = [];
      
      let proceed = true;
      
    let contributionTotalAmount = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < contributionData.length; i++) {
      contributionTotalAmount += +amount.toFixed(2);
    }
    
  
    let contributionBatchData = {
      requestType: "INVOICE_CREATE_MINI",
      currency: this.invoiceForm.get("currency").value,
      memberNumber: '',
      employerNumber: '',
      invoiceTotalAmount: `${contributionTotalAmount}`,
      invoiceDescription: this.invoiceForm.get('description').value,
      contributions: contributionData
    };
    
    if (this.authService.getUserDetails().userGroup == 'EMPLOYER') {
        contributionBatchData.employerNumber = this.currrentEmployerNumber;
      }
    if (this.authService.getUserDetails().userGroup == 'INDIVIDUAL_CONTRIBUTOR') {
        contributionBatchData.memberNumber = this.currrentEmployerNumber;
      }
    const memberNames = [];
    let memberNumberWithNames = [];
    let memberNamesWithoutNumbers = [];
    let memberNumbers = [];

   // 
    if(!`${this.userGroup}`.match('INDIVIDUAL_CONTRIBUTOR')) {
      for (const el of this.employeeUploadListDataSource) {
        if (el.memberSalary < this.thresholdAmount && this.invoiceForm.get("currency").value == 'TZS') {
          // tslint:disable-next-line: max-line-length
          this.alertReason = `Member salary (${el.memberSalary}) for member number ${el.memberNumber} (${el.memberNames}) is below the Threshold amount (${this.thresholdAmount} TZS).`;
          this.toastr.error(this.alertReason, 'Salary Threshold Amount',);
          this.showAlertDialog = true;
          this.spinner.hide();
          return;
        }
      }
    }

    for (const el of this.employeeUploadListDataSource) {
       memberNames.push(`${el.memberNames}`.toUpperCase());
       if (el.memberNumber !== null && el.memberNumber !== undefined &&  `${el.memberNumber}`.trim() !== '') {
        memberNumbers.push(el.memberNumber);
        memberNumberWithNames.push(`${el.memberNumber}`+`${el.memberNames}`.trim().toUpperCase());
       } else {
        memberNamesWithoutNumbers.push(`${el.memberNames}`.trim().toUpperCase());
       }
     }
    let duplicateMemberNames: any;
    let duplicateMemberNambers: any;

    for(let i = 0; i< memberNames.length;i++) {
      if (`${memberNames[i]}`.replace(/\s/g,'') == '') {
        this.alertReason = `Empty member name is not allowed`;
        this.showAlertDialog = true;
        this.spinner.hide();
        return;
      }
    }

    for (const iterator of memberNumberWithNames) {
       duplicateMemberNames = this.findDuplicates(memberNumberWithNames);
       if (duplicateMemberNames.length > 0 ) {
         this.alertReason = `The duplicate member names (${duplicateMemberNames}) detected in the list.`;
         this.showAlertDialog = true;
         this.spinner.hide();
         return;
       }
    }

  //   for (const iterator of memberNamesWithoutNumbers) {
  //     duplicateMemberNames = this.findDuplicates(memberNamesWithoutNumbers);
  //     if (duplicateMemberNames.length > 0 ) {
  //       //  this.alertReason = `The duplicate member names \n ${this.findDuplicates(memberNames)} detected`;
  //       this.alertReason = `The duplicate member names (${duplicateMemberNames})  detected in the list.`;
  //       this.showAlertDialog = true;
  //       this.spinner.hide();
  //       return;
  //     }
  //  }

    memberNumbers = memberNumbers.filter(function(str) {
    return /\S/.test(str);
   });

  //  
  //  memberNumbers = this.removeArrayItems(memberNumbers, undefined);

    // for (const iterator of memberNumbers) {
      //  if (!`${iterator}`.trim().match('') || iterator !== undefined || iterator !== null) {
        duplicateMemberNambers = this.findDuplicates(memberNumbers);
        if (duplicateMemberNambers.length > 0 ) {
          this.alertReason = `Duplicate member numbers \n ${this.findDuplicates(memberNumbers)} detected`;
          this.showAlertDialog = true;
          this.spinner.hide();
          return;
        // }
      //  }
    }
    if (this.isAddNewContributionToAnyExistingInvoice) {

        const addedContributionData = {
          requestType: "CONTRIBUTION_ADD",
          invoiceID: this.invoiceIdToAddContribution,
          memberNumber: '',
          employerNumber: '',
          contributions: contributionData
        };

        if (this.authService.getUserDetails().userGroup == 'EMPLOYER') {
          addedContributionData.employerNumber = this.currrentEmployerNumber;
        }
      if (this.authService.getUserDetails().userGroup == 'INDIVIDUAL_CONTRIBUTOR') {
        addedContributionData.memberNumber = this.currrentEmployerNumber;
        }

        this.utilities.postServiceCall(addedContributionData, 'backend/request').subscribe(res => {
          const serverResponse = res.json();
          this.spinner.hide();
          if (serverResponse.code == 2000) {
            sessionStorage.removeItem(AppSettings.invoiceDetailsKey);
            sessionStorage.setItem(AppSettings.invoiceDetailsKey,  this.securityService.encryptString(serverResponse.data));
            this.router.navigate(['/invoice/contributions']);
          } else {
            this.showAlertDialog = true;
            this.alertReason = serverResponse.message;
            // this.toastr.error(serverResponse.message, 'Failed to create invoice');
          }
        }, error => {
               this.spinner.hide();
               this.toastr.error('Something went wrong, please try again');
        });

        return; // this is very important
    }

    this.utilities.postServiceCall(contributionBatchData, this.invoiceCreateEndPoint).subscribe(res => {
        const serverResponse = res.json();
        if (serverResponse.code == 2000) {
          sessionStorage.removeItem(AppSettings.invoiceContributionTotalMembers);
          sessionStorage.removeItem(AppSettings.invoiceDetailsKey);
          sessionStorage.setItem(AppSettings.invoiceContributionTotalMembers, JSON.stringify(this.employeeUploadListDataSource.length));
          sessionStorage.setItem(AppSettings.invoiceDetailsKey,  this.securityService.encryptString(serverResponse.data));
          this.router.navigate(['/invoice/contributions']);
        } else {
          this.showAlertDialog = true;
          this.alertReason = serverResponse.message;
          this.logServerErrors(this.router.url, serverResponse.message, 'INVOICE_CREATE_MINI');
          // this.toastr.error(serverResponse.message, 'Request Failed');
        }
        this.spinner.hide();
      }, error => {
             this.spinner.hide();
             this.toastr.error('Something went wrong, please try again');
             this.logServerErrors(this.router.url, error, 'INVOICE_CREATE_MINI');
      });
  }

  calculateAmountDifferencePercent(amountDifference): number {
    const percent: number = (amountDifference / this.lastContributionTotalAmount) * 100;
    return +percent.toFixed(2);
  }

  removeArrayItems(arr, value) {
    let i = 0;
    while(i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  findDuplicates(arr) {
    let sorted_arr = arr.slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] == sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  }

  warningDecision(bit) {
    if (bit) {
      // do not continue, make adjustments first
      this.continueWithWarning = false;
      this.showWarningDialog = false;
    } else {
      // continue with warning
      this.continueWithWarning =  true;
      this.showWarningDialog = false;
    }
  }

  openPopUp() {
    if (this.invoiceForm.get("contributionMonth").invalid) {
      this.toastr.error("Please select contribution month to continue")
      return;
    }
 
    if (this.invoiceForm.get("contributionType").invalid) {
      this.toastr.error("Please select contribution type to continue")
      return;
    }

    this.openFileUploadDialog = true;
    this.value = [];
  }
  closePopUp() {
    this.openFileUploadDialog = false;
  }
  onFileValueChanged(event) {
    this.employeeUploadListDataSource = [];
    let data = {};
    let totalContributionsAmt = 0;
    this.uploadFailedMessage = 'Loading....';

    /*
      check file type
      if it is xlsx or xls call parseXLXS() function
    */
   if (`${event.file.name}`.endsWith('.xlsx') || `${event.file.name}`.endsWith('.xls')) {
    //  this.toastr.info('Excel File Loading (xlsx or xls detected)');
    this.parseXLXS(event);
    return;
   }
   this.spinner.show();
    const reader = new FileReader();
    reader.onload = () => {
      this.spinner.show();
      this.uploadFailedMessage =
        'Converting excel data format to json format....';
      const text = reader.result;
      this.hideInfoAlert = false;
      // notify('Please wait while system verify the correctness of the information provided.', 'info', 6000);
      this.showLoadingDialog = true;
      const json = csv2json(text, { parseNumbers: true });
      data = {
        data: json,
      };

      let memberSalaryConverted: any;
      let counter = 0;
      // tslint:disable-next-line: prefer-for-of
      let validate  = true;

      if (json.length > 1000) {
        validate = false
      }

      for (let i = 0; i < json.length; i++) {

          if (!json[0].hasOwnProperty('memberNumber')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include memberNumber header in your excelsheet on member numbers column.`;
              this.spinner.hide();
              return;
          }

          if (!json[0].hasOwnProperty('memberName')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include memberName header in your excelsheet on member names column.`;
              this.spinner.hide();
              return;
          }

          if (!json[0].hasOwnProperty('memberSalary')) {
              this.showAlertDialog = true;
              this.alertReason = `Please include memberSalary header in your excelsheet on members salaries column.`;
              // this.toastr.error('Please include memberName header in your excelsheet for members names column..');
              this.spinner.hide();
              return;
          }

        // if (json[i].memberSalary == null || json[i].memberSalary == '' || json[i].memberSalary === undefined) {
        //   this.toastr.error('Member Salary is required, please update your member\'s salary in the excel sheet.');
        //   this.spinner.hide();
        //   return;
        // }

        // if (!json[i].memberSalary) {
        //   this.toastr.error('Members Salaries are required, please include memberSalary field in your excelsheet.');
        //   this.spinner.hide();
        //   return;
        // }

        // if (!json[i].memberName) {
        //   this.toastr.error('Member Name is required, please include it in your excelsheet.');
        //   this.spinner.hide();
        //   return;
        // }
        // if (json[i].memberName == null || json[i].memberSalary == '') {
        //   this.toastr.error('Member Name is required, please include it in your excelsheet.');
        //   this.spinner.hide();
        //   return;
        // }

        if (json[i].memberSalary == null || json[i].memberSalary == '' || json[i].memberSalary === undefined) {
            this.showAlertDialog = true;
            this.alertReason = `Member Salary is Missing, please update your member\'s salary in the excel sheet.`;
            // this.toastr.error('Member Salary is required, please update your member\'s salary in the excel sheet 2.');
            this.spinner.hide();
            return;
        }

        if (json[i].memberName == null || json[i].memberName == '' || json[i].memberName == undefined) {
            this.showAlertDialog = true;
            this.alertReason = `MemberName column in your excelsheet is empty, please include it in your excelsheet.`;
            // this.toastr.error('Member Name is required, please include it in your excelsheet.');
            this.spinner.hide();
            return;
        }

        if (isNaN(json[i].memberSalary) && json[i].memberSalary !== undefined) {
          memberSalaryConverted = json[i].memberSalary.toString();
          memberSalaryConverted = +memberSalaryConverted.replace(/,/g, '');
        } else {
          memberSalaryConverted = json[i].memberSalary;
        }
        if (memberSalaryConverted < 0) {
          this.toastr.error(`Negative amount is not allowed for members salaries.`);
          this.spinner.hide();
          return;
        }
        if (memberSalaryConverted <  this.thresholdAmount) {
          this.showAlertDialog = true;
          // tslint:disable-next-line: max-line-length
          this.alertReason = `One of your member has salary below the threshold amount (${this.thresholdAmount}), current entries has been filtered out to allow only members with salary above threshold amount (${this.thresholdAmount}). please update the your member salary in the excel sheet.`;
          this.spinner.hide();
          return;
        }

        let memberNumbers = json[i].memberNumber === undefined ? '':`${json[i].memberNumber}`;

        if (memberNumbers.trim() === '' || memberNumbers === null || memberNumbers === undefined) {
           memberNumbers = ""
        }

        if (memberSalaryConverted >=  this.thresholdAmount) {

          if(this.contributionYear < 2023 )
          {
            this.employeeUploadListDataSource.push({
              id: ++counter,
              isMemberNumberValid: true,
              isMemberNamesValid: true,
              correctName: '',
              employerNumber: this.authService.getUserDetails().linkId,
              memberNames: json[i].memberName,
              memberSalary: memberSalaryConverted,
              memberNumber: memberNumbers,
              memberContribution: Math.round(((this.memberPercent / 100) * memberSalaryConverted)),
              employerContribution: Math.round(((this.employerPercent / 100) * memberSalaryConverted)),
              compensation: 0,
              amountContributedG: Math.round(((this.memberPercent / 100) * memberSalaryConverted) + ((this.employerPercent / 100) * memberSalaryConverted)) , 
              amountContributed:
              Math.round((((this.memberPercent / 100) * memberSalaryConverted) +
                ((this.employerPercent / 100) * memberSalaryConverted) 
                )),
              
            });
            totalContributionsAmt +=Math.round(
              (((this.memberPercent / 100) * +memberSalaryConverted) +
              ((this.employerPercent / 100) * memberSalaryConverted) 
              ));
          }
          else if(this.contributionYear < 2023 && this.isEmployerGov == true){
            this.employeeUploadListDataSource.push({
              id: ++counter,
              isMemberNumberValid: true,
              isMemberNamesValid: true,
              correctName: '',
              employerNumber: this.authService.getUserDetails().linkId,
              memberNames: json[i].memberName,
              memberSalary: memberSalaryConverted,
              memberNumber: memberNumbers,
              memberContribution: Math.round(((this.memberPercent / 100) * memberSalaryConverted)),
              employerContribution: Math.round(((this.employerPercent / 100) * memberSalaryConverted)),
              compensation: 0,
              amountContributedG: Math.round(((this.memberPercent / 100) * memberSalaryConverted) + ((this.employerPercent / 100) * memberSalaryConverted)),   
              amountContributed:
              Math.round((((this.memberPercent / 100) * memberSalaryConverted) +
                ((this.employerPercent / 100) * memberSalaryConverted) 
                )),
              
            });
            totalContributionsAmt +=
            Math.round((((this.memberPercent / 100) * +memberSalaryConverted) +
              ((this.employerPercent / 100) * memberSalaryConverted) 
              ));
          }

          else if(this.contributionYear == 2023 && this.isEmployerGov == true && this.compensationZeroGov == true && this.contributionTypeValue == 4 ){
            this.employeeUploadListDataSource.push({
              id: ++counter,
              isMemberNumberValid: true,
              isMemberNamesValid: true,
              correctName: '',
              employerNumber: this.currrentEmployerNumber,
              memberNames: json[i].memberName,
              memberSalary: memberSalaryConverted,
              memberNumber: memberNumbers == 'undefined' ? '':memberNumbers,
              memberContribution: Math.round(((this.memberPercent / 100) * memberSalaryConverted)),
              employerContribution: Math.round(((this.employerPercent / 100) * memberSalaryConverted)),
              compensation: 0,
              amountContributedG: Math.round(((this.memberPercent / 100) * memberSalaryConverted) + ((this.employerPercent / 100) * memberSalaryConverted)),   
              amountContributed:
              Math.round((((this.memberPercent / 100) * memberSalaryConverted) +
                ((this.employerPercent / 100) * memberSalaryConverted) 
                )),
              
            });
            totalContributionsAmt +=
            Math.round((((this.memberPercent / 100) * +memberSalaryConverted) +
              ((this.employerPercent / 100) * memberSalaryConverted) 
              ));

              //this.compensationZeroGov = false;
          }

          else if(this.contributionYear >= 2023 && this.contributionTypeValue == 4 ){
            this.employeeUploadListDataSource.push({
              id: ++counter,
              isMemberNumberValid: true,
              isMemberNamesValid: true,
              correctName: '',
              employerNumber: this.authService.getUserDetails().linkId,
              memberNames: json[i].memberName,
              memberSalary: memberSalaryConverted,
              memberNumber: memberNumbers,
              memberContribution: 0,
              employerContribution: 0,
              compensation: Math.round(((this.compensationPercent / 100) * memberSalaryConverted)), 
              //amountContributed:(((this.compensationPercent / 100) * memberSalaryConverted)),
              amountContributed: 0,
              amountContributedG: Math.round(((this.compensationPercent / 100) * memberSalaryConverted)) 
              
            });
            totalContributionsAmt +=Math.round((((this.compensationPercent / 100) * memberSalaryConverted)))
          }

          else if(this.contributionYear == 2023 && this.isEmployerGov == true && this.compensationZeroGov == true )
          {
            this.employeeUploadListDataSource.push({
              id: ++counter,
              isMemberNumberValid: true,
              isMemberNamesValid: true,
              correctName: '',
              employerNumber: this.authService.getUserDetails().linkId,
              memberNames: json[i].memberName,
              memberSalary: memberSalaryConverted,
              memberNumber: memberNumbers,
              memberContribution: Math.round(((this.memberPercent / 100) * memberSalaryConverted)),
              employerContribution: Math.round(((this.employerPercent / 100) * memberSalaryConverted)),
              compensation: 0,
              amountContributedG: Math.round(((this.memberPercent / 100) * memberSalaryConverted) + ((this.employerPercent / 100) * memberSalaryConverted)),   
              amountContributed:
              Math.round((((this.memberPercent / 100) * memberSalaryConverted) +
                ((this.employerPercent / 100) * memberSalaryConverted) 
                )),
              
            });
            totalContributionsAmt +=
            Math.round((((this.memberPercent / 100) * +memberSalaryConverted) +
              ((this.employerPercent / 100) * memberSalaryConverted) 
              ));
          }

          else{
            this.employeeUploadListDataSource.push({
              id: ++counter,
              isMemberNumberValid: true,
              isMemberNamesValid: true,
              correctName: '',
              employerNumber: this.authService.getUserDetails().linkId,
              memberNames: json[i].memberName,
              memberSalary: memberSalaryConverted,
              memberNumber: memberNumbers,
              memberContribution: Math.round(((this.memberPercent / 100) * memberSalaryConverted)),
              employerContribution: Math.round(((this.employerPercent / 100) * memberSalaryConverted)),
              compensation: Math.round(((this.compensationPercent / 100) * memberSalaryConverted)),
              amountContributedG: Math.round(((this.memberPercent / 100) * memberSalaryConverted) + ((this.employerPercent / 100) * memberSalaryConverted) + ((this.compensationPercent / 100) * memberSalaryConverted)),  
              amountContributed:
              Math.round((((this.memberPercent / 100) * memberSalaryConverted) +
                ((this.employerPercent / 100) * memberSalaryConverted) 
                // ((this.compensationPercent / 100) * memberSalaryConverted)
                )),
              
            });
            totalContributionsAmt +=
              (((this.memberPercent / 100) * +memberSalaryConverted) +
              ((this.employerPercent / 100) * memberSalaryConverted) +
              ((this.compensationPercent / 100) * memberSalaryConverted)
              );
          }

         
          
          

            let memberNumber = `${json[i].memberNumber}`;
            const memberNames = json[i].memberName;

            if (memberNumber.trim() === '' || memberNumber === null || memberNumber === undefined) {
              memberNumber = ""
            }

            if (`${memberNames}`.trim() === '' || memberNames === null || memberNames === undefined) {
              this.isMemberNamesMatch = false;
            }

            const data =  {
              requestType: "MEMBERS_VERIFY",
              members: [
                  {
                      memberNumber,
                      memberNames
                  }
              ]
          };

          if (validate) {
            this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
              this.hideInfoAlert = false;
              const serverRes = res.json();
              if (serverRes.code == 2000) {
                this.totalMembersWithInvalidNumbers = 0;
                this.totalMembersWithNameMismatch = 0;
                 for (const el of serverRes.data) {
                   if (el.status == 2015) {  // invalid member number
                    this.isMemberNumberValid = false;
                    this.isMemberNamesMatch = false;
                    this.employeeUploadListDataSource[i].isMemberNumberValid = false;
                    this.employeeUploadListDataSource[i].isMemberNamesValid = false;
                    this.totalMembersWithInvalidNumbers += 1;
                   }

                   if (el.status == 2016) {  // Difference in names
                    this.isMemberNamesMatch = false;
                    this.isMemberNumberValid = true;
                    this.employeeUploadListDataSource[i].isMemberNumberValid = true;
                    this.employeeUploadListDataSource[i].isMemberNamesValid = false;
                    this.employeeUploadListDataSource[i].correctName = `${el.registeredName}`;
                    this.totalMembersWithNameMismatch += 1;
                   }

                   if (el.status == 2000) {  // Validation Successful / Empty Member Number
                    this.isMemberNumberValid = true;
                    this.isMemberNamesMatch = true;
                    this.employeeUploadListDataSource[i].isMemberNumberValid = true;
                    this.employeeUploadListDataSource[i].isMemberNamesValid = true;
                    this.employeeUploadListDataSource[i].correctName = `${el.registeredName}`;
                   }
                 }
                 this.updateInvalidConstraints();
                 sessionStorage.removeItem("employeeUploadListDataSource")
                 //
                 sessionStorage.setItem("employeeUploadListDataSource",JSON.stringify(this.employeeUploadListDataSource))
              }
            }, err => {
             this.toastr.info("Something went wrong while validating the correctness of the information given, please make sure you have internet connection, or procced without validations.");
             this.isMemberNumberValid = true;
             this.isMemberNamesMatch = true;
            });
          }
        }
        // this.employeeUploadListDataSource = json;
        this.uploadFailedMessage = 'Conversion completed....';
        }
      this.spinner.hide();
      this.showLoadingDialog = true;
    };
    reader.onloadend = () => {
      this.spinner.hide();
      setTimeout(() => {
        this.showLoadingDialog = false
      }, 7000);
      //this.showLoadingDialog = false;
      alert('Data upload finished');
      this.uploadFailedMessage = 'Data uploaded successfully....';
      this.openFileUploadDialog = false;
      this.notifyDataSourceChanges();
    };
    reader.readAsText(event.file);
    this.hideEmployeeUploadForm = true;
    this.hideEmployeeListDataGrid = false;
  }

  onSubmitEmployeesContributionList() {
    let totalContributionsAmt = 0;
    const dataSource = this.employeeUploadListDataSource;
    this.spinner.show();
    for (let i = 0; i < dataSource.length; i++) {
      let amount = 0;
      dataSource[i].memberId = dataSource[i].memberId.toString();
      dataSource[i].memberNumber = dataSource[i].memberNumber.toString();
      dataSource[i].employerNumber = dataSource[i].employerNumber.toString();
      dataSource[i].amountContributed = +dataSource[i].amountContributed
        .toString()
        .replace(/,/g, '');
      dataSource[i].memberContribution = +dataSource[i].memberContribution
        .toString()
        .replace(/,/g, '');
      dataSource[i].memberSalary = +dataSource[i].memberSalary
        .toString()
        .replace(/,/g, '');
      dataSource[i].employerContribution = +dataSource[i].employerContribution
        .toString()
        .replace(/,/g, '');
      amount = dataSource[i].amountContributed;
      totalContributionsAmt += amount;
    }
    this.contributionService.setEmployerNumber(
      this.authService.getUserDetails().linkId
    );
    this.contributionService.setTotalAmount(totalContributionsAmt);
    this.contributionService.setContributions(dataSource);
    if (this.isContributionBatchRequest) {
      this.spinner.hide();
      sessionStorage.removeItem(AppSettings.contributionBatchRequestKey);
      sessionStorage.setItem(AppSettings.contributionBatchRequestKey,  this.securityService.encryptString(this.contributionService.getContributionData()));
      this.router.navigate(['/contributions/batch/months'], { queryParams: { frombatchservice: true },  queryParamsHandling: 'merge' });
      return;
    }
    this.utilities
      .postServiceCall(
        this.contributionService.getContributionData(),
        this.createInvoiceEndpointCall
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          const serveRes = res.json();
          if (serveRes.code == 2000) {
            this.toastr.success(serveRes.message);
            this.hideEmployeeListDataGrid = true;
            this.hideEmployeeUploadForm = true;
            this.hideDataUploadControls = true;
            this.isUploadFileMethodSelected = true;
            this.hideInitialInvoiceDetails = false;
            this.invoiceService.setInvoiceDetails(
              serveRes.data.invoiceNo,
              serveRes.data.invoiceID,
              serveRes.data.invoiceDetailId,
              serveRes.data.invoiceTrackNo,
              serveRes.data.dateCreated,
              serveRes.data.amountPaid,
              `${this.userDetails.firstName} ${this.userDetails.middleName} ${this.userDetails.lastName}`,
              null,
              this.userDetails.email,
              'Member Contribution',
              serveRes.data.narration,
              serveRes.data.contributionYear,
              serveRes.data.contributionMonth,
              serveRes.data.currencyCode,
              true
            );
            if (this.isMultipleContributionMode) {
              this.invoiceListStorageService.setSingleInvoiceDetails(
                serveRes.data.invoiceID,
                serveRes.data.invoiceDetailId,
                serveRes.data.invoiceNo,
                serveRes.data.amountPaid,
                serveRes.data.contributionYear,
                serveRes.data.contributionMonth,
                this.authService.getUserDetails().linkId,
                serveRes.data.invoiceTrackNo
              );
              this.router.navigate(['/multiple-contributions']);
              this.toastr.success(
                'Contribution data added',
                'Multiple Contributions'
              );
              return;
            }
            this.router.navigate(['/contribution/invoice']);
          } else {
            this.toastr.error(serveRes.message);
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error, 'Request Failed');
        }
      );
  }

  backToUploadForm() {
    this.hideEmployeeListDataGrid = true;
    this.hideEmployeeUploadForm = false;
    this.employeeUploadListDataSource = [];
  }

  onLoadPreviousContributionData() {
    const data = {
      requestType : 'LAST_CONTRIBUTION',
      linkId: this.authService.getUserDetails().linkId
    };
    this.lastContributionTotalAmount = 0;
    this.spinner.show();
    this.utilities
      .postServiceCall(data, 'portal/request')
      .subscribe(
        res => {
          const serverRes = res.json();
          this.spinner.hide();
          if (serverRes.code === 2000) {
            const arr = serverRes.data;
            let counter = 0;
            if (arr === null || arr === undefined) {
            return;
           }
            for (const el of serverRes.data) {
              this.lastContributionTotalAmount += el.amountContributed;
              this.employeeUploadListDataSource.push({
                id: ++counter,
                memberNames: `${el.firstName} ${el.middleName} ${el.surName}`,
                employerNumber: this.authService.getUserDetails().linkId,
                memberSalary: el.salaryBeforeTax,
                memberNumber: el.memberNumber,
                memberContribution: Math.round(((this.memberPercent / 100) * el.salaryBeforeTax)),
                employerContribution: Math.round(((this.employerPercent / 100) * el.salaryBeforeTax)),
                compensation: Math.round(((this.compensationPercent / 100) * el.salaryBeforeTax)),
                amountContributed: Math.round(((this.memberPercent / 100) * el.salaryBeforeTax) + ((this.employerPercent / 100) * el.salaryBeforeTax)),
                
              });
            }
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  notifyDataSourceChanges() {

    if (this.authService.getUserDetails().userGroup == 'EMPLOYER') {
    this.showCalculatingDatasourceChanges = true;
    this.memberSalary = this.employeeUploadListDataSource.map(item => item.memberSalary).reduce((a, b) => a + b, 0);
    this.memberContribution =  this.employeeUploadListDataSource.map(item => item.memberContribution).reduce((a, b) => a + b, 0);
    this.employerContribution =  this.employeeUploadListDataSource.map(item => item.employerContribution).reduce((a, b) => a + b, 0);
    this.amountContributed =  this.employeeUploadListDataSource.map(item => item.amountContributed).reduce((a, b) => a + b, 0) + this.employeeUploadListDataSource.map(item => item.compensation).reduce((a, b) => a + b, 0);
    this.compensation=this.employeeUploadListDataSource.map(item => item.compensation).reduce((a, b) => a + b, 0);
    this.contributionAmount = this.employeeUploadListDataSource.map(item => item.amountContributed).reduce((a, b) => a + b, 0)

    this.totalMembers = 0;
    this.totalMembersWithoutNames = 0;
    this.totalMembersWithoutNumbers = 0;
    this.totalMembersWithoutNamesAndNumber = 0;
    this.totalMembersWithInvalidNumbers = 0;
    this.totalMembersWithNameMismatch = 0;
    this.totalDuplicateMemberNumbers = 0;
    this.totalDuplicateMembers = 0;

    // isMemberNumberValid: true,
    // isMemberNamesValid: true,
    // memberNames
    // memberNumber

    let counter = {};

    // const count = arr.reduce((accumulator, value) => {
    //   return {...accumulator, [value]: (accumulator[value] || 0) + 1};
    // }, {});

    // var map = this.employeeUploadListDataSource.reduce(function(prev, cur) {
    //   prev[cur] = (prev[cur] || 0) + 1;
    //   return prev;
    // }, {});
    
    let memberNames = [];
    let memberNumbers = [];
    let memberNamesWithoutNumbers = [];
    let memberNumberWithNames = [];
    let countInvalidMemberNumbers = 0;
    let countInvalidMemberNames = 0;
    let invalidMemberNumberArray = [];
    let invalidMemberNameArray = [];

    for (const el of this.employeeUploadListDataSource) {
      memberNames.push(`${el.memberNames}`.toUpperCase());
      if (el.memberNumber !== null && el.memberNumber !== undefined &&  `${el.memberNumber}`.trim() !== '') {
       memberNumbers.push(el.memberNumber);
       memberNumberWithNames.push(`${el.memberNumber}`+`${el.memberNames}`.trim().toUpperCase());
      } else {
       memberNamesWithoutNumbers.push(`${el.memberNames}`.trim().toUpperCase());
      }
      if ((el.memberNumber === null || el.memberNumber === undefined || `${el.memberNumber}`.trim() !== '') && `${el.memberNames}`.replace(/\s/g,'') == '') {
        this.totalMembersWithoutNamesAndNumber += 1;
      }

      if (`${el.memberNames}`.replace(/\s/g,'') == '') {
        this.totalMembersWithoutNames += 1
      }
    }
   let totalDuplicateMembers: any;
   let duplicateMemberNumbers: any;

  totalDuplicateMembers = this.findDuplicates(memberNumberWithNames);

  memberNumbers = memberNumbers.filter(function(str) {
   return /\S/.test(str);
  });

  duplicateMemberNumbers = this.findDuplicates(memberNumbers);

  this.totalMembersWithoutNumbers = memberNamesWithoutNumbers.length;
  this.totalMembersWithInvalidNumbers = countInvalidMemberNumbers;
  this.totalMembersWithNameMismatch = countInvalidMemberNames;
  this.totalDuplicateMemberNumbers = duplicateMemberNumbers.length;
  this.totalDuplicateMembers = totalDuplicateMembers.length;
  this.totalMembersWithNameMismatch = invalidMemberNameArray.length;
  this.totalMembersWithInvalidNumbers = invalidMemberNumberArray.length;
  this.totalMembers = this.employeeUploadListDataSource.length;

  this.showCalculatingDatasourceChanges = false;
  }
}

refreshSummary() {
  this.notifyDataSourceChanges();
  this.updateInvalidConstraints();
}

updateInvalidConstraints() {
  this.totalMembersWithNameMismatch = 0;
  this.totalMembersWithInvalidNumbers = 0;
  this.showCalculatingValueBanner = true;
  let invalidMemberNumberArray = [];
  let invalidMemberNameArray = [];
  for (const el of this.employeeUploadListDataSource) {
    if (!el.isMemberNumberValid) {
      invalidMemberNumberArray.push(el.isMemberNumberValid)
    }
    if (!el.isMemberNamesValid) {
      invalidMemberNameArray.push(el.isMemberNamesValid);
    }
  }

  this.totalMembersWithNameMismatch = invalidMemberNameArray.length;
  this.totalMembersWithInvalidNumbers = invalidMemberNumberArray.length;
  this.showCalculatingValueBanner = false;
}

   funCompare( a, b ) {
    if ( a.memberNames < b.memberNames ){
      return -1;
    }
    if ( a.memberNames > b.memberNames ){
      return 1;
    }
    return 0;
  }

  customizeText(data) {
    return "Total Members: " + data.value;
}

  loadSelectedContributionMonth(e) {
  
    this.spinner.show();
    const data = {
      requestType: 'EMPLOYER_CONTRIBUTIONS',
      employerNo: this.authService.getUserDetails().linkId,
      contributingPeriod: e.ContributingPeriod
    };
    this.lastContributionTotalAmount = 0;
    this.utilities
      .postServiceCall(data, 'portal/request')
      .subscribe(
        res => {
          this.employeeUploadListDataSource = [];
          const serverRes = res.json();
          //
          this.hideEmployeeListDataGrid = false;
          if (serverRes.code === 2000) {
            this.toastr.success(serverRes.message);
            // memberId: el.memberId,
             let counter = 0;
             let index = 0;
             
            if(this.contributionYear < 2023 )
            {

              for (const el of serverRes.data) {
                this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
                this.employeeUploadListDataSource.push({
                  id: ++counter,
                  correctName: '',
                  isMemberNumberValid: true,
                  isMemberNamesValid: true,
                  memberNames: `${el.FirstName} ${el.MiddleName} ${el.SurName}`,
                  employerNumber: this.authService.getUserDetails().linkId,
                  memberSalary: el.BaseAmount,
                  memberNumber: el.MemberNumber,
                  memberContribution: Math.round(((this.memberPercent / 100) * el.BaseAmount)),
                  employerContribution: Math.round(((this.employerPercent / 100) * el.BaseAmount)),
                  compensation: 0,
                  amountContributed: Math.round(((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount)),
                  amountContributedG: Math.round(((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount))
                  
                });
              }
              
            }
            else if (this.contributionYear < 2023 && this.isEmployerGov == true)
            {
              for (const el of serverRes.data) {
                this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
                this.employeeUploadListDataSource.push({
                  id: ++counter,
                  correctName: '',
                  isMemberNumberValid: true,
                  isMemberNamesValid: true,
                  memberNames: `${el.FirstName} ${el.MiddleName} ${el.SurName}`,
                  employerNumber: this.authService.getUserDetails().linkId,
                  memberSalary: el.BaseAmount,
                  memberNumber: el.MemberNumber,
                  memberContribution: Math.round(((this.memberPercent / 100) * el.BaseAmount)),
                  employerContribution: Math.round(((this.employerPercent / 100) * el.BaseAmount)),
                  compensation: 0,
                  amountContributed: Math.round(((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount)),
                  amountContributedG: Math.round(((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount))
                  
                });
              }

              
            }

            else if(this.contributionYear == 2023 && this.isEmployerGov == true && this.compensationZeroGov == true && this.contributionTypeValue == 4 )
            {
            for (const el of serverRes.data) {
              this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
              this.employeeUploadListDataSource.push({
                id: ++counter,
                correctName: '',
                isMemberNumberValid: true,
                isMemberNamesValid: true,
                memberNames: `${el.FirstName} ${el.MiddleName} ${el.SurName}`,
                employerNumber: this.currrentEmployerNumber,
                memberSalary: el.BaseAmount,
                memberNumber: el.MemberNumber,
                memberContribution: Math.round(((this.memberPercent / 100) * el.BaseAmount)),
                employerContribution: Math.round(((this.employerPercent / 100) * el.BaseAmount)),
                compensation: 0,
                amountContributed: Math.round(((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount)),
                amountContributedG: Math.round(((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount))
              });
            }
            //this.isEmployerGov = false;
            //this.compensationZeroGov = false;
          }

            else if(this.contributionYear == 2023 && this.contributionTypeValue == 4 )
            {
              for (const el of serverRes.data) {
                this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
                this.employeeUploadListDataSource.push({
                  id: ++counter,
                  correctName: '',
                  isMemberNumberValid: true,
                  isMemberNamesValid: true,
                  memberNames: `${el.FirstName} ${el.MiddleName} ${el.SurName}`,
                  employerNumber: this.authService.getUserDetails().linkId,
                  memberSalary: el.BaseAmount,
                  memberNumber: el.MemberNumber,
                  memberContribution: 0,
                  employerContribution: 0,
                  compensation: Math.round(((this.compensationPercent / 100) * el.BaseAmount)),
                  //amountContributed: ((this.compensationPercent / 100) * el.BaseAmount),
                  amountContributed: 0,
                  amountContributedG: Math.round(((this.compensationPercent / 100) * el.BaseAmount))
                  
                });
              }
            }

            
            else if(this.contributionYear == 2023 && this.isEmployerGov == true && this.compensationZeroGov == true ){

              for (const el of serverRes.data) {
                this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
                this.employeeUploadListDataSource.push({
                  id: ++counter,
                  correctName: '',
                  isMemberNumberValid: true,
                  isMemberNamesValid: true,
                  memberNames: `${el.FirstName} ${el.MiddleName} ${el.SurName}`,
                  employerNumber: this.authService.getUserDetails().linkId,
                  memberSalary: el.BaseAmount,
                  memberNumber: el.MemberNumber,
                  memberContribution: Math.round(((this.memberPercent / 100) * el.BaseAmount)),
                  employerContribution: Math.round(((this.employerPercent / 100) * el.BaseAmount)),
                  compensation: 0,
                  amountContributed: Math.round(((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount)),
                  amountContributedG: Math.round(((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount))
                  
                });
              }

              //this.compensationZeroGov = false;
            }
            
            else{
              for (const el of serverRes.data) {
                this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
                this.employeeUploadListDataSource.push({
                  id: ++counter,
                  correctName: '',
                  isMemberNumberValid: true,
                  isMemberNamesValid: true,
                  memberNames: `${el.FirstName} ${el.MiddleName} ${el.SurName}`,
                  employerNumber: this.authService.getUserDetails().linkId,
                  memberSalary: el.BaseAmount,
                  memberNumber: el.MemberNumber,
                  memberContribution: Math.round(((this.memberPercent / 100) * el.BaseAmount)),
                  employerContribution: Math.round(((this.employerPercent / 100) * el.BaseAmount)),
                  compensation: Math.round(((this.compensationPercent / 100) * el.BaseAmount)),
                  amountContributed: Math.round(((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount)),
                  amountContributedG: Math.round(((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount) + ((this.compensationPercent / 100) * el.BaseAmount))
                  
                });
              }
            }


            sessionStorage.removeItem("employeeUploadListDataSource")
           // 
            sessionStorage.setItem("employeeUploadListDataSource",JSON.stringify(this.employeeUploadListDataSource))


            //
            this.notifyDataSourceChanges();
            this.spinner.hide();
            // do sorting
            // this.employeeUploadListDataSource = this.employeeUploadListDataSource.sort(this.funCompare)
            this.showDialog = false;
          } else {
            this.toastr.error(serverRes.message);
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error('Something went wrong, Please try again.');
        }
      );
  }

  onLoadExistingEmployeesList() {

    if (this.invoiceForm.get("contributionMonth").invalid) {
      this.toastr.error("Please select contribution month to continue")
      return;
    }

    
    if (this.invoiceForm.get("contributionType").invalid) {
      this.toastr.error("Please select contribution type to continue")
      return;
    }

    this.spinner.show();
    let memberType = 'EMPLOYER';
    if (this.authService.getUserDetails().userGroup === 'EMPLOYER' ) {
      memberType = 'EMPLOYER';
    }

    if (this.authService.getUserDetails().userGroup === 'INDIVIDUAL_CONTRIBUTOR') {
      memberType = 'INDIVIDUAL_CONTRIBUTOR';
    }

    const tps = {
      requestType: 'LAST_CONTRIBUTIONS',
      linkId: this.currrentEmployerNumber,
      memberType: memberType
    }
    this.utilities.postServiceCall(tps, 'portal/request').subscribe(res => {
      const serverRes = res.json();
      this.spinner.hide();
      if (serverRes.code == 2000) {
      this.lastContributionsDataSet = [];
      let counter = 0;
      for (const iterator of serverRes.data) {
        this.lastContributionsDataSet.push({
          id: ++counter,
          ContributionYear: `${iterator.ContributingPeriod}`.substring(0, 4),
          ContributionMonth: this.contributionInWord(`${iterator.ContributingPeriod}`.substring(4)),
          ContributingPeriod: iterator.ContributingPeriod
          // Narration: iterator.Narration,
          // InvoiceID: iterator.InvoiceID,
          // InvoiceAmount: iterator.i[0].InvoiceAmount,
          // currency: 'TZS'
        });
      }
      this.showDialog = true;
      }
    }, err => {
      this.spinner.hide();
      this.toastr.error('Something went wrong, please try again later.');
    });
  }

  onUploadExcelDataFile() {
    this.employeeUploadListDataSource = [];
    this.steps = 2;
    this.title = 'Upload Excel Sheet File Method';
    this.hideEmployeeUploadForm = false;
    this.hideEmployeeFetchedDataListDataGrid = true;
    this.hideEmployeeListManualEntrance = true;
    this.hideDataUploadControls = true;
    this.isUploadFileMethodSelected = true;
  }

  onManualDataEntrance() {
    this.employeeUploadListDataSource = [];
    this.steps = 2;
    this.title = 'Press the plus(+) sign to add new row';
    this.hideEmployeeListManualEntrance = false;
    this.hideDataUploadControls = true;
    this.hideEmployeeFetchedDataListDataGrid = true;
    this.hideEmployeeUploadForm = true;
    this.hideEmployeeListDataGrid = false;
    this.isUploadFileMethodSelected = false;
  }

  onSubmitInvoiceDetails() {
    if (this.invoiceForm.invalid) {
      this.toastr.error('Fill all the fields to continue', 'Data Required');
      return;
    }

    this.contributionService.setPaymentTypeID(1);
    this.contributionService.setContributionYear(
      this.invoiceForm.get('contributionYear').value
    );
    this.contributionService.setContributionMonth(
      this.invoiceForm.get('contributionMonth').value
    );
    this.contributionService.setNarration(
      this.invoiceForm.get('description').value
    );

    const invoiceDetails: InvoiceModel = {
      paymentTypeID: 1,
      contributionsYear: this.invoiceForm.get('contributionYear').value,
      contributionsMonth: this.invoiceForm.get('contributionMonth').value,
      narration: this.invoiceForm.get('description').value,
    };
    this.store.dispatch(
      InvoiceActions.BeginCreateInvoiceAction({ payload: invoiceDetails })
    );

    this.steps = 1;
    this.hideBackButton = false;
    this.title = 'Choose The Way To Create Employees Contribution List';
    this.hideInitialInvoiceDetails = true;
    this.hideDataUploadControls = false;
  }

  onBackButtonPressed() {
    // this.hideInitialInvoiceDetails = false;
    if (this.steps === 1) {
      this.hideInitialInvoiceDetails = false;
      this.hideBackButton = true;
    } else if (this.steps === 2) {
      this.title = 'Choose The Way To Create Employees Contribution List';
      // if ( this.hideDataUploadControls && this.steps === 2) {
      //   this.title = 'Choose The Way To Create Employees Contribution List';
      //   this.hideDataUploadControls = false;
      //   this.hideEmployeeListDataGrid = true;
      //   this.hideEmployeeUploadForm = true;
      //   return;
      // }
      this.hideEmployeeListDataGrid = true;
      this.hideEmployeeUploadForm = true;
      this.hideDataUploadControls = false;
    }
  }
  selectedChanged(e) {
    // this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
  }
  onToolBarPreparing(e, downloadAction, onLoadExistingEmployeesList, openPopUp) {
    e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'fa fa-cloud-download',
          type: 'default',
          text: 'Download Manuals',
          onClick: downloadAction.bind(this)
        }

    },
    {
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'repeat',
        type: 'default',
        text: 'Load Last Contribution Data',
        onClick: onLoadExistingEmployeesList.bind(this)
      }
    },
    {
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'fa fa-table',
        type: 'default',
        text: 'Upload Excel File',
        onClick: openPopUp.bind(this)
      }
  }
    );
  }

  downloadOption() {
    this.showDownloadTutorialDialog = true;
  }
  //  DataGrid's row Calculations & Validations
  calculateMemberContribution(rowData) {
    if (isNaN(rowData.memberSalary) && rowData.memberSalary !== undefined) {
      let num: any = rowData.memberSalary.toString();
      num = +num.replace(/,/g, '');
      if (num === 0) {
        return 0;
      }
      return ((this.memberPercent / 100) * num);
    } else {
      return rowData.memberContribution;
    }
  }
  calculateEmployerContribution(rowData) {
    if (isNaN(rowData.memberSalary) && rowData.memberSalary !== undefined) {
      let num: any = rowData.memberSalary.toString();
      num = +num.replace(/,/g, '');
      if (num === 0) {
        return 0;
      }
      return ((this.employerPercent / 100) * num);
    } else {
      return rowData.employerContribution;
    }
  }

  calculateCompensation(rowData) {
    if (isNaN(rowData.memberSalary) && rowData.memberSalary !== undefined) {
      let num: any = rowData.memberSalary.toString();
      num = +num.replace(/,/g, '');
      if (num === 0) {
        return 0;
      }
      return ((this.compensationPercent / 100) * num);
    } else {
      return rowData.employerContribution;
    }
  }

  memberSalaryFilter(rowData) {
    if (isNaN(rowData.memberSalary) && rowData.memberSalary !== undefined) {
      let num: any = rowData.memberSalary.toString();
      num = +num.replace(/,/g, '');
      if (num < this.thresholdAmount && this.invoiceForm.get("currency").value == 'TZS') {
        // this.toastr.error('Member salary is below minimum threshold');
        return 0;
      }
      return num;
    } else if (rowData.memberSalary === null || rowData.memberSalary === undefined || rowData.memberSalary === '') {
      // this.toastr.error('Please enter member salary');
      return 0;
    } else {
      return rowData.memberSalary;
    }
  }
  calculateAmountContributed(rowData) {
    if (isNaN(rowData.memberSalary) && rowData.memberSalary !== undefined) {
      let num: any = rowData.memberSalary.toString();
      num = +num.replace(/,/g, '');
      // return num1 + num2;
      if (num === 0) {
        return 0;
      }
      return (((this.memberPercent / 100) * num + (this.employerPercent / 100)) * num );
    } else {
      return rowData.amountContributed;
    }
  }

  validateMemberSalaryThreshold(e) {
    let num: any = e.value;
    num = +num.replace(/,/g, '');
    return num >= this.thresholdAmount;
  }

  onMemberNumberValidation(e) {
    if (e.memberNumber) {
      e.promise = this.validateMemberNumber(
        e.newData.memberNumber,
        this.validateMemberNumberEndPoint
      ).subscribe(
        (result) => {
          const serverRes = result.json();
          if (serverRes.code == 2000) {
            // e.errorText = serverRes.errorText;
            e.isValid = serverRes.isValid;
          } else {
            this.toastr.error(
              'Something went wrong while validating members number'
            );
          }
        },
        (error) =>
          this.toastr.error(
            'Something went wrong while validating members number'
          )
      );
    }
  }

  validateMemberNumber(memberNumber, endPoint) {
    const data = {
      memberNumber,
    };
    return this.utilities.postServiceCall(data, endPoint);
  }
  onRowInserting(e) {
    

    e.data.employerNumber = this.currrentEmployerNumber;
    // e.date.memberNumber = this.currrentEmployerNumber;
    e.data.contributionYear = this.contributionService.getContributionYear();
    e.data.contributionMonth = this.contributionService.getContributionMonth();
  }
  onRowInserted(e) {
    //
    if (this.invoiceForm.invalid) {
      this.toastr.error('Please enter contribution month,contribution type to continue')
      this.employeeUploadListDataSource.splice(0)
      return;
    }
  
    this.isNewRowInserted = true;
    this.hideInfoAlert = false;
    // notify('Please wait while system verify the correctness of the information provided.', 'info', 6000);
    const baseSalary = +e.data.memberSalary;
    let memberNumber = e.data.memberNumber;
    const memberNames = e.data.memberNames;
    if(baseSalary == 0 || baseSalary === undefined || baseSalary === null || isNaN(baseSalary)) {
      this.toastr.error("Member's salary is below the threshold amount");
    } else if (baseSalary < this.thresholdAmount && this.invoiceForm.get("currency").value == 'TZS') {
      this.toastr.error("Member's salary is below the threshold amount");
    } else {

      if (`${memberNumber}`.trim() === '' || memberNumber === null || memberNumber === undefined) {
        memberNumber = ""
      }

      if (`${memberNames}`.trim() === '' || memberNames === null || memberNames === undefined) {
        this.isMemberNamesMatch = false;
      }

      const data =  {
        requestType: "MEMBERS_VERIFY",
        members: [
            {
                memberNumber,
                memberNames
            }
        ]
    };

    this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
      this.spinner.show();
      this.hideInfoAlert = false;
      const serverRes = res.json();
      if (serverRes.code == 2000) {
        // this.totalMembersWithInvalidNumbers = 0;
        // this.totalMembersWithNameMismatch = 0;
         for (const el of serverRes.data) {
           if (el.status == 2015) {  // invalid member number
            this.isMemberNumberValid = false;
            this.isMemberNamesMatch = false;
            e.data.isMemberNumberValid = false;
            e.data.isMemberNamesValid = false;
           }

           if (el.status == 2016) {  // Difference in names
            this.isMemberNamesMatch = false;
            this.isMemberNumberValid = true;
            e.data.isMemberNumberValid = true;
            e.data.isMemberNamesValid = false;
            e.data.correctName = `${el.registeredName}`;
           }

           if (el.status == 2000) {  // Validation Successful / Empty Member Number
            this.isMemberNumberValid = true;
            this.isMemberNamesMatch = true;
            e.data.isMemberNumberValid = true;
            e.data.isMemberNamesValid = true;
            e.data.correctName = `${el.registeredName}`;
           }
         }
        // this.notifyDataSourceChanges();
      }
      this.spinner.hide();
      this.updateInvalidConstraints();
    }, err => {
     this.toastr.info("Something went wrong while validating the correctness of the information given, please make sure you have internet connection, or procced without validations.");
     this.isMemberNumberValid = true;
     this.isMemberNamesMatch = true;

     this.spinner.hide();
    });

     if(this.contributionYear < 2023)
      {
        const memberContribution: number = Math.round(((this.memberPercent / 100) * baseSalary));
        const employerContribution: number = Math.round(((this.employerPercent / 100) * baseSalary));
        const compensation: number = 0;
  
        e.data.memberContribution = Math.round(memberContribution);
        e.data.employerContribution = Math.round(employerContribution);
        e.data.compensation =Math.round(compensation)
        e.data.amountContributed = Math.round(memberContribution + employerContribution);
        e.data.amountContributedG = Math.round(memberContribution + employerContribution)
        
        let counter =0
         this.employeeUploadListDataSourceN.push({
          id: ++counter,
          
          isMemberNumberValid: e.data.isMemberNumberValid,
          isMemberNamesValid: e.data.isMemberNamesValid,
          memberNames: e.data.memberNames,
          employerNumber: e.data.employeeNumber,
          memberSalary: e.data.memberSalary,
          memberNumber: e.data.memberNumber,
          memberContribution: memberContribution,
          employerContribution: employerContribution,
          compensation: compensation,
          amountContributed: memberContribution + employerContribution,
          amountContributedG: memberContribution + employerContribution + compensation,
          correctName: e.data.correctName,
         })
        
      }

      else if(this.contributionTypeValue == 4 && this.contributionYear < 2023)
      {
        const memberContribution: number = Math.round(((this.memberPercent / 100) * baseSalary));
        const employerContribution: number = Math.round(((this.employerPercent / 100) * baseSalary));
        const compensation: number = 0;
  
        e.data.memberContribution = memberContribution;
        e.data.employerContribution = employerContribution;
        e.data.compensation =compensation
        e.data.amountContributed = memberContribution + employerContribution;
        e.data.amountContributedG = memberContribution + employerContribution

        let counter =0
         this.employeeUploadListDataSourceN.push({
          id: ++counter,
          
          isMemberNumberValid: e.data.isMemberNumberValid,
          isMemberNamesValid: e.data.isMemberNamesValid,
          memberNames: e.data.memberNames,
          employerNumber: e.data.employeeNumber,
          memberSalary: e.data.memberSalary,
          memberNumber: e.data.memberNumber,
          memberContribution: memberContribution,
          employerContribution: employerContribution,
          compensation: compensation,
          amountContributed: memberContribution + employerContribution,
          amountContributedG: memberContribution + employerContribution + compensation,
          correctName: e.data.correctName,
         })
      }


      else if(this.contributionTypeValue == 4 && this.contributionYear == 2023 && this.isEmployerGov == true && this.compensationZeroGov == true)
      {
        const memberContribution: number = Math.round(((this.memberPercent / 100) * baseSalary));
        const employerContribution: number = Math.round(((this.employerPercent / 100) * baseSalary));
        const compensation: number = 0;
  
        e.data.memberContribution = memberContribution;
        e.data.employerContribution = employerContribution;
        e.data.compensation =compensation
        e.data.amountContributed = memberContribution + employerContribution;
        e.data.amountContributedG = memberContribution + employerContribution 

        let counter =0
         this.employeeUploadListDataSourceN.push({
          id: ++counter,
          
          isMemberNumberValid: e.data.isMemberNumberValid,
          isMemberNamesValid: e.data.isMemberNamesValid,
          memberNames: e.data.memberNames,
          employerNumber: e.data.employeeNumber,
          memberSalary: e.data.memberSalary,
          memberNumber: e.data.memberNumber,
          memberContribution: memberContribution,
          employerContribution: employerContribution,
          compensation: compensation,
          amountContributed: memberContribution + employerContribution,
          amountContributedG: memberContribution + employerContribution + compensation,
          correctName: e.data.correctName,
         })
      }

      else if(this.contributionYear == 2023 && this.isEmployerGov == true && this.compensationZeroGov == true)
      {
        
        const memberContribution: number = Math.round(((this.memberPercent / 100) * baseSalary));
        const employerContribution: number = Math.round(((this.employerPercent / 100) * baseSalary));
        const compensation: number = 0;
    
        e.data.memberContribution = memberContribution;
        e.data.employerContribution = employerContribution;
        e.data.compensation =compensation
        e.data.amountContributed = memberContribution + employerContribution;
        e.data.amountContributedG = memberContribution + employerContribution 

        let counter =0
         this.employeeUploadListDataSourceN.push({
          id: ++counter,
          
          isMemberNumberValid: e.data.isMemberNumberValid,
          isMemberNamesValid: e.data.isMemberNamesValid,
          memberNames: e.data.memberNames,
          employerNumber: e.data.employeeNumber,
          memberSalary: e.data.memberSalary,
          memberNumber: e.data.memberNumber,
          memberContribution: memberContribution,
          employerContribution: employerContribution,
          compensation: compensation,
          amountContributed: memberContribution + employerContribution,
          amountContributedG: memberContribution + employerContribution + compensation,
          correctName: e.data.correctName,
         })
        
      }



      else if(this.contributionTypeValue == 4 && this.contributionYear >= 2023)
      {
         const memberContribution: number = 0;
         const employerContribution: number = 0;
         const compensation: number = Math.round(((this.compensationPercent / 100) * baseSalary));
  
        e.data.memberContribution = memberContribution;
        e.data.employerContribution = employerContribution;
        e.data.compensation =compensation
        e.data.amountContributed = 0;
        e.data.amountContributedG = compensation 
        let counter =0
         this.employeeUploadListDataSourceN.push({
          id: ++counter,
          
          isMemberNumberValid: e.data.isMemberNumberValid,
          isMemberNamesValid: e.data.isMemberNamesValid,
          memberNames: e.data.memberNames,
          employerNumber: e.data.employeeNumber,
          memberSalary: e.data.memberSalary,
          memberNumber: e.data.memberNumber,
          memberContribution: memberContribution,
          employerContribution: employerContribution,
          compensation: compensation,
          amountContributed: memberContribution + employerContribution,
          amountContributedG: memberContribution + employerContribution + compensation,
          correctName: e.data.correctName,
         })
      }

      else
      {
         const memberContribution: number = Math.round(((this.memberPercent / 100) * baseSalary));
         const employerContribution: number = Math.round(((this.employerPercent / 100) * baseSalary));
         const compensation: number = Math.round(((this.compensationPercent / 100) * baseSalary));
  
         e.data.memberContribution = memberContribution;
         e.data.employerContribution = employerContribution;
         e.data.compensation =compensation
         e.data.amountContributed = memberContribution + employerContribution ;
         e.data.amountContributedG = memberContribution + employerContribution + compensation ;

         let counter =0
         this.employeeUploadListDataSourceN.push({
          id: ++counter,
          
          isMemberNumberValid: e.data.isMemberNumberValid,
          isMemberNamesValid: e.data.isMemberNamesValid,
          memberNames: e.data.memberNames,
          employerNumber: e.data.employeeNumber,
          memberSalary: e.data.memberSalary,
          memberNumber: e.data.memberNumber,
          memberContribution: memberContribution,
          employerContribution: employerContribution,
          compensation: compensation,
          amountContributed: memberContribution + employerContribution,
          amountContributedG: memberContribution + employerContribution + compensation,
          correctName: e.data.correctName,
         })
        
      }
      
       
      sessionStorage.removeItem("employeeUploadListDataSource")
      // 
      sessionStorage.setItem("employeeUploadListDataSource",JSON.stringify(this.employeeUploadListDataSourceN))
      // calculate member & employer contribution, as well as total amount contributed
      // const memberContribution: number = ((this.memberPercent / 100) * baseSalary);
      // const employerContribution: number = ((this.employerPercent / 100) * baseSalary);
      // const compensation: number = ((this.compensationPercent / 100) * baseSalary);

      // e.data.memberContribution = memberContribution;
      // e.data.employerContribution = employerContribution;
      // e.data.compensation =compensation
      // e.data.amountContributed = memberContribution + employerContribution + compensation;

      this.notifyDataSourceChanges();
    }
  }

  onRowUpdated(e) {
    this.employeeUploadListDataSourceN =[]
    // e.component.refresh(true);
    this.hideInfoAlert = false;
    // notify('Please wait while system verify the correctness of the information provided.', 'info', 6000);
    const baseSalary = +e.data.memberSalary;
    // if (baseSalary < 180000) {
    //   return false;
    // }
    if(baseSalary == 0 || baseSalary === undefined || baseSalary === null || isNaN(baseSalary)) {
      this.toastr.error("Member's salary is below the threshold amount")
      return;
    } else if (baseSalary < this.thresholdAmount && this.invoiceForm.get("currency").value == 'TZS') {
      this.toastr.error("Member's salary is below the threshold amount");
    } else {
      let memberNumber = e.data.memberNumber;
      const memberNames = e.data.memberNames;
      if (`${memberNumber}`.trim() === '' || memberNumber === null || memberNumber === undefined) {
        memberNumber = ""
      }

      if (`${memberNames}`.trim() === '' || memberNames === null || memberNames === undefined) {
        this.isMemberNamesMatch = false;
      }

      
      const data =  {
        requestType: "MEMBERS_VERIFY",
        members: [
            {
                memberNumber,
                memberNames
            }
        ]
    };

    this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
      this.spinner.show();
      // notify('Please wait while system verify the correctness of the information provided.', 'info', 6000);
      this.hideInfoAlert = false;
      const serverRes = res.json();
      if (serverRes.code == 2000) {
        // this.totalMembersWithInvalidNumbers = 0;
        // this.totalMembersWithNameMismatch = 0;
         for (const el of serverRes.data) {
           if (el.status == 2015) {  // invalid member number
            this.isMemberNumberValid = false;
            this.isMemberNamesMatch = false;
            e.data.isMemberNumberValid = false;
            e.data.isMemberNamesValid = false;
           }

           if (el.status == 2016) {  // Difference in names
            this.isMemberNamesMatch = false;
            this.isMemberNumberValid = true;
            e.data.isMemberNumberValid = true;
            e.data.isMemberNamesValid = false;
            e.data.correctName = `${el.registeredName}`;
           }

           if (el.status == 2000) {  // Validation Successful / Empty Member Number
            this.isMemberNumberValid = true;
            this.isMemberNamesMatch = true;
            e.data.isMemberNumberValid = true;
            e.data.isMemberNamesValid = true;
            e.data.correctName = `${el.registeredName}`;
           }
         }
        //  this.notifyDataSourceChanges();
      }
      this.spinner.hide();
      this.updateInvalidConstraints();
    }, err => {
     this.toastr.info("Something went wrong while validating the correctness of the information given, please make sure you have internet connection, or procced without validations.");
     this.isMemberNumberValid = true;
     this.isMemberNamesMatch = true;

     this.spinner.hide();
    });
      
      if(this.contributionYear < 2023)
      {
        
        const memberContribution: number = Math.round(((this.memberPercent / 100) * baseSalary));
        const employerContribution: number = Math.round(((this.employerPercent / 100) * baseSalary));
        const compensation: number = 0;
  
        e.data.memberContribution = memberContribution;
        e.data.employerContribution = employerContribution;
        e.data.compensation =compensation
        e.data.amountContributed = memberContribution + employerContribution ;
        e.data.amountContributedG = memberContribution + employerContribution ;

        let counter =0
         this.employeeUploadListDataSourceN.push({
          id: ++counter,
          
          isMemberNumberValid: e.data.isMemberNumberValid,
          isMemberNamesValid: e.data.isMemberNamesValid,
          memberNames: e.data.memberNames,
          employerNumber: e.data.employeeNumber,
          memberSalary: e.data.memberSalary,
          memberNumber: e.data.memberNumber,
          memberContribution: memberContribution,
          employerContribution: employerContribution,
          compensation: compensation,
          amountContributed: memberContribution + employerContribution,
          amountContributedG: memberContribution + employerContribution + compensation,
          correctName: e.data.correctName,
         })
      }

      else if(this.contributionTypeValue == 4 && this.contributionYear < 2023)
      {
        
        const memberContribution: number = Math.round(((this.memberPercent / 100) * baseSalary));
        const employerContribution: number = Math.round(((this.employerPercent / 100) * baseSalary));
        const compensation: number = 0;
  
        e.data.memberContribution = memberContribution;
        e.data.employerContribution = employerContribution;
        e.data.compensation =compensation
        e.data.amountContributed = memberContribution + employerContribution;
        e.data.amountContributedG = memberContribution + employerContribution ;

        let counter =0
        this.employeeUploadListDataSourceN.push({
         id: ++counter,
         
         isMemberNumberValid: e.data.isMemberNumberValid,
         isMemberNamesValid: e.data.isMemberNamesValid,
         memberNames: e.data.memberNames,
         employerNumber: e.data.employeeNumber,
         memberSalary: e.data.memberSalary,
         memberNumber: e.data.memberNumber,
         memberContribution: memberContribution,
         employerContribution: employerContribution,
         compensation: compensation,
         amountContributed: memberContribution + employerContribution,
         amountContributedG: memberContribution + employerContribution + compensation,
         correctName: e.data.correctName,
        })
      }


      else if(this.contributionTypeValue == 4 && this.contributionYear == 2023 && this.isEmployerGov == true && this.compensationZeroGov == true)
      {
        
        const memberContribution: number = Math.round(((this.memberPercent / 100) * baseSalary));
        const employerContribution: number = Math.round(((this.employerPercent / 100) * baseSalary));
        const compensation: number = 0;
  
        e.data.memberContribution = memberContribution;
        e.data.employerContribution = employerContribution;
        e.data.compensation =compensation
        e.data.amountContributed = memberContribution + employerContribution;
        e.data.amountContributedG = memberContribution + employerContribution ; 

        let counter =0
        this.employeeUploadListDataSourceN.push({
         id: ++counter,
         
         isMemberNumberValid: e.data.isMemberNumberValid,
         isMemberNamesValid: e.data.isMemberNamesValid,
         memberNames: e.data.memberNames,
         employerNumber: e.data.employeeNumber,
         memberSalary: e.data.memberSalary,
         memberNumber: e.data.memberNumber,
         memberContribution: memberContribution,
         employerContribution: employerContribution,
         compensation: compensation,
         amountContributed: memberContribution + employerContribution,
         amountContributedG: memberContribution + employerContribution + compensation,
         correctName: e.data.correctName,
        })
      }

      
      else if(this.contributionYear == 2023 && this.isEmployerGov == true && this.compensationZeroGov == true)
      {
        
        const memberContribution: number = Math.round(((this.memberPercent / 100) * baseSalary));
        const employerContribution: number = Math.round(((this.employerPercent / 100) * baseSalary));
        const compensation: number = 0;
  
        e.data.memberContribution = memberContribution;
        e.data.employerContribution = employerContribution;
        e.data.compensation =compensation
        e.data.amountContributed = memberContribution + employerContribution;
        e.data.amountContributedG = memberContribution + employerContribution ; 

        let counter =0
        this.employeeUploadListDataSourceN.push({
         id: ++counter,
         
         isMemberNumberValid: e.data.isMemberNumberValid,
         isMemberNamesValid: e.data.isMemberNamesValid,
         memberNames: e.data.memberNames,
         employerNumber: e.data.employeeNumber,
         memberSalary: e.data.memberSalary,
         memberNumber: e.data.memberNumber,
         memberContribution: memberContribution,
         employerContribution: employerContribution,
         compensation: compensation,
         amountContributed: memberContribution + employerContribution,
         amountContributedG: memberContribution + employerContribution + compensation,
         correctName: e.data.correctName,
        })
      }

      else if(this.contributionTypeValue == 4 && this.contributionYear >= 2023)
      {
        
         const memberContribution: number = 0;
         const employerContribution: number = 0;
         const compensation: number = Math.round(((this.compensationPercent / 100) * baseSalary));
  
        e.data.memberContribution = memberContribution;
        e.data.employerContribution = employerContribution;
        e.data.compensation =compensation
        e.data.amountContributed = 0;
        e.data.amountContributedG = compensation; 

        let counter =0
        this.employeeUploadListDataSourceN.push({
         id: ++counter,
         
         isMemberNumberValid: e.data.isMemberNumberValid,
         isMemberNamesValid: e.data.isMemberNamesValid,
         memberNames: e.data.memberNames,
         employerNumber: e.data.employeeNumber,
         memberSalary: e.data.memberSalary,
         memberNumber: e.data.memberNumber,
         memberContribution: memberContribution,
         employerContribution: employerContribution,
         compensation: compensation,
         amountContributed: memberContribution + employerContribution,
         amountContributedG: memberContribution + employerContribution + compensation,
         correctName: e.data.correctName,
        })
      }

      else
      {
        
         const memberContribution: number = Math.round(((this.memberPercent / 100) * baseSalary));
         const employerContribution: number = Math.round(((this.employerPercent / 100) * baseSalary));
         const compensation: number = Math.round(((this.compensationPercent / 100) * baseSalary));
  
         e.data.memberContribution = memberContribution;
         e.data.employerContribution = employerContribution;
         e.data.compensation =compensation
         e.data.amountContributed = memberContribution + employerContribution ;
         e.data.amountContributedG = memberContribution + employerContribution + compensation;

         let counter =0
         this.employeeUploadListDataSourceN.push({
          id: ++counter,
          
          isMemberNumberValid: e.data.isMemberNumberValid,
          isMemberNamesValid: e.data.isMemberNamesValid,
          memberNames: e.data.memberNames,
          employerNumber: e.data.employeeNumber,
          memberSalary: e.data.memberSalary,
          memberNumber: e.data.memberNumber,
          memberContribution: memberContribution,
          employerContribution: employerContribution,
          compensation: compensation,
          amountContributed: memberContribution + employerContribution,
          amountContributedG: memberContribution + employerContribution + compensation,
          correctName: e.data.correctName,
         })
         
      }
  
      // calculate member & employer contribution, as well as total amount contributed  
      sessionStorage.removeItem("employeeUploadListDataSource")
      // 
      sessionStorage.setItem("employeeUploadListDataSource",JSON.stringify(this.employeeUploadListDataSourceN))
      this.notifyDataSourceChanges();
    }
  }

  onRowRemoved(e) {
    this.updateInvalidConstraints();
    this.notifyDataSourceChanges();
    // 
  }

  onEditingStart(e) {
    // e.data.employerNumber = this.currrentEmployerNumber;
  }
  getMonthName(e) {
    // tslint:disable-next-line: prefer-for-of
    for (
      let index = 0;
      index < this.contributionService.getMonthsDataset().length;
      index++
    ) {
      if (
        this.contributionService.getMonthsDataset()[index].id ==
        e.data.contributionMonth
      ) {
        e.data.contributionMonth = this.contributionService.getMonthsDataset()[
          index
        ].text;
      }
    }
  }

  verifyMemberNumberAndNames(number, name): boolean {
    let memberNumber = `${number}`;
    const memberNames = `${name}`;

    if (`${memberNumber}`.trim() === '' || memberNumber === null || memberNumber === undefined) {
      memberNumber = ""
    }

    if (`${memberNames}`.trim() === '' || memberNames === null || memberNames === undefined) {
      this.isMemberNamesMatch = false;
    }

    const data =  {
      requestType: "MEMBERS_VERIFY",
      members: [
          {
              memberNumber,
              memberNames
          }
      ]
  };

  this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
    const serverRes = res.json();
    if (serverRes.code == 2000) {
       for (const el of serverRes.data) {
         if (el.status == 2015) {  // invalid member number
          this.isMemberNumberValid = false;
          this.isMemberNamesMatch = false;
         }

         if (el.status == 2016) {  // Difference in names
          this.isMemberNamesMatch = false;
          this.isMemberNumberValid = true;
         }

         if (el.status == 2000) {  // Validation Successful / Empty Member Number
          this.isMemberNumberValid = true;
          this.isMemberNamesMatch = true;
         }
       }
    }
  }, err => {
   this.isMemberNumberValid = true;
   this.isMemberNamesMatch = true;
  });

  return true;
  }

  resetContributionPage() {
  this.invoiceForm.reset();
  this.employeeUploadListDataSource = [];
  this.memberSalary = 0;
  this.memberContribution =  0;
  this.employerContribution =  0;
  this.amountContributed =  0;
  this.totalMembers = 0;
  this.totalMembersWithoutNames = 0;
  this.totalMembersWithoutNumbers = 0;
  this.totalMembersWithoutNamesAndNumber = 0;
  this.totalMembersWithInvalidNumbers = 0;
  this.totalMembersWithNameMismatch = 0;
  this.totalDuplicateMemberNumbers = 0;
  this.totalDuplicateMembers = 0;
  this.totalContributionMonths = 0;
  }

  onMonthSelectionChanged(e) {

   
    
    //  if(e.addedItems.length > 0)
    //  {
    //    this.isContributionMonthSelected = true;
    //  }
    //  else  {
    //   this.isContributionMonthSelected = false;
    //  }

     this.monthSelected.push(e.addedItems)
     
     if(e.addedItems.length > 0)
     {
      
      this.monthSelected.push(e.addedItems)
     

      for(let iterator of e.addedItems)
     {
   
      this.myArrays.push(iterator.text)
     }

     }

     else if(e.removedItems.length > 0) {
      //
      
      this.myArrays =this.myArrays.filter((item:any) => item != e.removedItems[0].text)
     
       
     }

     this.LimitedMonthGov =[]
    
     for(let iterator of this.myArrays)
     {
      
      this.LimitedMonthGov.push(iterator)
     }
     //

      //"February","March","April","May","June"
     //removedItem
     if(this.LimitedMonthGov.includes("January") || this.LimitedMonthGov.includes("February") || 
      this.LimitedMonthGov.includes("March") || this.LimitedMonthGov.includes("April") || this.LimitedMonthGov.includes("May") ||
      this.LimitedMonthGov.includes("June"))
     {
       //  
       this.compensationZeroGov = true
     }
     
     else{
      //
      this.compensationZeroGov = false
     }

    if (e.addedItems.length > 0) {
      for (const element of e.addedItems) {
        this.selectedContributionMonths.push(element);
      }
    }

    if (e.removedItems.length > 0) {
      for (let i = this.selectedContributionMonths.length - 1; i >= 0; --i) {
        if (this.selectedContributionMonths[i].id === e.removedItems[0].id) {
            this.selectedContributionMonths.splice(i, 1);
        }
    }
    }
    if (this.selectedContributionMonths.length > 0) {
      const type = '';
      this.monthInText = [];
      for (const iterator of this.selectedContributionMonths) {
        this.monthInText.push(`${iterator.text}`);
      }
      if (this.invoiceForm.get('contributionType').value === 1) {

      }
      this.selectedContributionYear = this.invoiceForm.get('contributionYear').value;
      // tslint:disable-next-line: max-line-length
      if(`${this.userGroup}`.match('INDIVIDUAL_CONTRIBUTOR')) {
        this.descriptionString = `Monthly individual contribution for ${this.accountName}, for ${this.monthInText.join(', ')} ${this.selectedContributionYear}.`;
      } else {
        this.descriptionString = `Monthly ${e.value === undefined ? `contributions` : this.getContributionTypeName(e.value)} for ${this.accountName}, for ${this.monthInText.join(', ')} ${this.selectedContributionYear}.`;
      }
      this.invoiceForm.get('description').patchValue(this.descriptionString);
    }

    if (this.selectedContributionMonths.length < 1) {
      this.descriptionString = '';
      this.invoiceForm.get('description').reset();
    }

    this.totalContributionMonths = this.selectedContributionMonths.length;
  }

  onContributionYearChanged(e) {
    // e.previousValue
    this.contributionYear = e.value;

   
 
      if(e.value < 2023 && this.hideDataUploadControls == true)
      {
        this.contributionType = [
          {
            id: 1,
            text: 'Contributions'
          }
        ];

      }

      else if(e.value < 2023)
      {
        
        this.contributionType = [
          {
            id: 1,
            text: 'Contributions'
          },
          {
            id: 2,
            text: 'Arrears'
          },
          {
            id: 3,
            text: 'Adjustments'
          }
        ];
      }
      
      else if(e.value >= 2023 && this.hideDataUploadControls == false){
        
        this.contributionType = [
          {
            id: 1,
            text: 'Contributions'
          },
          {
            id: 2,
            text: 'Arrears'
          },
          {
            id: 3,
            text: 'Adjustments'
          },
          {
            id: 4,
            text: 'Compensation'
          }
        ];
      }

    
    if(e.value < 2023 && this.hideDataUploadControls == false)
    {
     
      this.UpdatedArray = JSON.parse(sessionStorage.getItem("employeeUploadListDataSource"))
      //
      

      if(this.UpdatedArray){
        this.employeeUploadListDataSource.splice(0)
        let counter = 0;
     
      for (const el of this.UpdatedArray) {
        this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
        this.employeeUploadListDataSource.push({
          id: ++counter,
          correctName: el.correctName,
          isMemberNumberValid: el.isMemberNumberValid,
          isMemberNamesValid: el.isMemberNamesValid,
          memberNames: el.memberNames,
          employerNumber: el.employerNumber, 
          memberSalary: el.memberSalary,  
          memberNumber: el.memberNumber,
          memberContribution: Math.round(((this.memberPercent / 100) * el.memberSalary)),        
          employerContribution: Math.round(((this.employerPercent / 100) * el.memberSalary)),      
          compensation: 0,                 
          amountContributed: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary)),
          amountContributedG: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary)), 
        });
      } }
   
    }
    

    else if(e.value >=2023 && this.contributionTypeValue == 4 && this.compensationZeroGov == true && this.isEmployerGov == true)
    {
      this.UpdatedArray = JSON.parse(sessionStorage.getItem("employeeUploadListDataSource"))
      //
  
      if(this.UpdatedArray){
        this.employeeUploadListDataSource.splice(0)
        let counter = 0;
      for (const el of this.UpdatedArray) {
        this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
        this.employeeUploadListDataSource.push({
          id: ++counter,
          correctName: el.correctName,
          isMemberNumberValid: el.isMemberNumberValid,
          isMemberNamesValid: el.isMemberNamesValid,
          memberNames: el.memberNames,
          employerNumber: el.employerNumber,
          memberSalary: el.memberSalary,
          memberNumber: el.memberNumber,
          memberContribution: Math.round(((this.memberPercent / 100) * el.memberSalary)),
          employerContribution: Math.round(((this.employerPercent / 100) * el.memberSalary)),
          compensation: 0,
          amountContributed: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary)),
          amountContributedG: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary)), 
        });
      }}
     }

    else if(e.value >=2023 && this.contributionTypeValue == 4)
    {
      this.UpdatedArray = JSON.parse(sessionStorage.getItem("employeeUploadListDataSource"))
      //

     
      if(this.UpdatedArray){
        
    this.employeeUploadListDataSource.splice(0)
    let counter = 0;

      for (const el of this.UpdatedArray) {
      this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
      this.employeeUploadListDataSource.push({
        id: ++counter,
        correctName: el.correctName,
        isMemberNumberValid: el.isMemberNumberValid,
        isMemberNamesValid: el.isMemberNamesValid,
        memberNames: el.memberNames,
        employerNumber: el.employerNumber,
        memberSalary: el.memberSalary,
        memberNumber: el.memberNumber,
        memberContribution: 0,
        employerContribution: 0,
        compensation: Math.round(((this.compensationPercent / 100) * el.memberSalary)),
        //amountContributed: ((this.compensationPercent / 100) * el.memberSalary),
        amountContributed: 0,
        amountContributedG: Math.round(((this.compensationPercent / 100) * el.memberSalary)), 
      });
    }
  }
    }
    
    else if(e.value >= 2023)
    {

    this.UpdatedArray = JSON.parse(sessionStorage.getItem("employeeUploadListDataSource"))
    //


    if(this.UpdatedArray){
      
    this.employeeUploadListDataSource.splice(0)
    let counter = 0;

    for (const el of this.UpdatedArray) {
      this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
      this.employeeUploadListDataSource.push({
        id: ++counter,
        correctName: el.correctName,
        isMemberNumberValid: el.isMemberNumberValid,
        isMemberNamesValid: el.isMemberNamesValid,
        memberNames: el.memberNames,
        employerNumber: el.employerNumber,
        memberSalary: el.memberSalary,
        memberNumber: el.memberNumber,
        memberContribution: Math.round(((this.memberPercent / 100) * el.memberSalary)),
        employerContribution: Math.round(((this.employerPercent / 100) * el.memberSalary)),
        compensation: Math.round(((this.compensationPercent / 100) * el.memberSalary)),
        amountContributed: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary)),
        amountContributedG: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary) + ((this.compensationPercent / 100) * el.memberSalary)), 
      });
    }
  }
    }


    this.refreshSummary()

    if (new Date().getFullYear() == e.value) {
      this.months = [];
      for (let index = 0; index < this.contributionService.getMonthsDataset().length; index++) {
        // if (index <= 5) {
          this.months.push(this.contributionService.getMonthsDataset()[index]);
        // }
      }
    } else {
      this.months = [];
      this.months = this.contributionService.getMonthsDataset();
    }
    
    if (this.selectedContributionMonths.length > 0) {
      this.selectedContributionYear = e.value;
      if (e.previousValue !== e.value) {
        this.descriptionString = this.descriptionString.replace(e.previousValue, e.value);
        this.invoiceForm.get('description').patchValue(this.descriptionString);
      }
    }
  }


  onRowUpdatedINDIVIDUAL(e) {
    // e.component.refresh(true);
    this.hideInfoAlert = false;
    // notify('Please wait while system verify the correctness of the information provided.', 'info', 6000);
    const baseSalary = +e.data.memberSalary;
    // if (baseSalary < 180000) {
    //   return false;
    // }
    if(baseSalary == 0 || baseSalary === undefined || baseSalary === null || isNaN(baseSalary)) {
      this.toastr.error("Member's salary is below the threshold amount")
      return;
    } else if (baseSalary < this.thresholdAmount && this.invoiceForm.get("currency").value == 'TZS') {
      this.toastr.error("Member's salary is below the threshold amount");
    } else {
      let memberNumber = e.data.memberNumber;
      const memberNames = e.data.memberNames;

      if (`${memberNumber}`.trim() === '' || memberNumber === null || memberNumber === undefined) {
        memberNumber = ""
      }

      if (`${memberNames}`.trim() === '' || memberNames === null || memberNames === undefined) {
        this.isMemberNamesMatch = false;
      }


      const data =  {
        requestType: "MEMBERS_VERIFY",
        members: [
            {
                memberNumber,
                memberNames
            }
        ]
    };

    this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
      this.spinner.show();
      // notify('Please wait while system verify the correctness of the information provided.', 'info', 6000);
      this.hideInfoAlert = false;
      const serverRes = res.json();
      if (serverRes.code == 2000) {
        // this.totalMembersWithInvalidNumbers = 0;
        // this.totalMembersWithNameMismatch = 0;
         for (const el of serverRes.data) {
           if (el.status == 2015) {  // invalid member number
            this.isMemberNumberValid = false;
            this.isMemberNamesMatch = false;
            e.data.isMemberNumberValid = false;
            e.data.isMemberNamesValid = false;
           }

           if (el.status == 2016) {  // Difference in names
            this.isMemberNamesMatch = false;
            this.isMemberNumberValid = true;
            e.data.isMemberNumberValid = true;
            e.data.isMemberNamesValid = false;
            e.data.correctName = `${el.registeredName}`;
           }

           if (el.status == 2000) {  // Validation Successful / Empty Member Number
            this.isMemberNumberValid = true;
            this.isMemberNamesMatch = true;
            e.data.isMemberNumberValid = true;
            e.data.isMemberNamesValid = true;
            e.data.correctName = `${el.registeredName}`;
           }
         }
        //  this.notifyDataSourceChanges();
      }
      this.spinner.hide();
      this.updateInvalidConstraints();
    }, err => {
     this.toastr.info("Something went wrong while validating the correctness of the information given, please make sure you have internet connection, or procced without validations.");
     this.isMemberNumberValid = true;
     this.isMemberNamesMatch = true;

     this.spinner.hide();
    });
      // calculate member & employer contribution, as well as total amount contributed
      const memberContribution: number = Math.round(((this.memberPercent / 100) * baseSalary));
      const employerContribution: number = Math.round(((this.employerPercent / 100) * baseSalary));

      e.data.memberContribution = memberContribution;
      e.data.employerContribution = employerContribution;
      e.data.amountContributed = memberContribution + employerContribution;

      this.notifyDataSourceChanges();
    }
  }
  

  onContributionTypeChanged(e) {
 
    this.contributionTypeValue =null
    this.contributionTypeValue = e.value;
    //
  
    if(this.contributionYear < 2023)
    {
      this.UpdatedArray = JSON.parse(sessionStorage.getItem("employeeUploadListDataSource"))
      //
      this.employeeUploadListDataSource.splice(0)
      let counter = 0;
      
      for (const el of this.UpdatedArray) {
        this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
        this.employeeUploadListDataSource.push({
          id: ++counter,
          correctName: el.correctName,
          isMemberNumberValid: el.isMemberNumberValid,
          isMemberNamesValid: el.isMemberNamesValid,
          memberNames: el.memberNames,
          employerNumber: el.employerNumber,
          memberSalary: el.memberSalary,
          memberNumber: el.memberNumber,
          memberContribution: Math.round(((this.memberPercent / 100) * el.memberSalary)),
          employerContribution: Math.round(((this.employerPercent / 100) * el.memberSalary)),
          compensation: 0,
          amountContributed: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary)),
          amountContributedG: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary)), 
        });
    }
  }
  
  
  
    if(e.value  == 4 && this.contributionYear >= 2023 && this.compensationZeroGov == true && this.isEmployerGov == true)
    {
      this.UpdatedArray = JSON.parse(sessionStorage.getItem("employeeUploadListDataSource"))
      //
      this.employeeUploadListDataSource.splice(0)
      let counter = 0;
  
      for (const el of this.UpdatedArray) {
        this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
        this.employeeUploadListDataSource.push({
          id: ++counter,
          correctName: el.correctName,
          isMemberNumberValid: el.isMemberNumberValid,
          isMemberNamesValid: el.isMemberNamesValid,
          memberNames: el.memberNames,
          employerNumber: el.employerNumber,
          memberSalary: el.memberSalary,
          memberNumber: el.memberNumber,
          memberContribution: Math.round(((this.memberPercent / 100) * el.memberSalary)),
          employerContribution: Math.round(((this.employerPercent / 100) * el.memberSalary)),
          compensation: 0,
          amountContributed: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary)),
          amountContributedG: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary)), 
        });
      }
    }
  
    else if(e.value  == 4 && this.contributionYear >= 2023)
    { 
      //this.showUpdate = true;
      //this.hideDataUploadControls = true
      // var grid = $('#gridContainer').dxDataGrid('instance');  
      // grid.option('dataSource', []);  
      //this.hideDataUploadControls = true
      this.UpdatedArray = JSON.parse(sessionStorage.getItem("employeeUploadListDataSource"))
      //
      this.employeeUploadListDataSource.splice(0)
      let counter = 0;
      for (const el of this.UpdatedArray) {
        this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
        this.employeeUploadListDataSource.push({
          id: ++counter,
          correctName: el.correctName,
          isMemberNumberValid: el.isMemberNumberValid,
          isMemberNamesValid: el.isMemberNamesValid,
          memberNames: el.memberNames,
          employerNumber: el.employerNumber,
          memberSalary: el.memberSalary,
          memberNumber: el.memberNumber,
          memberContribution: 0,
          employerContribution: 0,
          compensation: Math.round(((this.compensationPercent / 100) * el.memberSalary)),
         // amountContributed: ((this.compensationPercent / 100) * el.memberSalary),
          amountContributed: 0,
          amountContributedG: Math.round(((this.compensationPercent / 100) * el.memberSalary)), 
        });
      }
   
    }
    else if(e.value == 4 && this.contributionYear < 2023)
    {
      this.UpdatedArray = JSON.parse(sessionStorage.getItem("employeeUploadListDataSource"))
      //
      this.employeeUploadListDataSource.splice(0)
      let counter = 0;
  
      for (const el of this.UpdatedArray) {
        this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
        this.employeeUploadListDataSource.push({
          id: ++counter,
          correctName: el.correctName,
          isMemberNumberValid: el.isMemberNumberValid,
          isMemberNamesValid: el.isMemberNamesValid,
          memberNames: el.memberNames,
          employerNumber: el.employerNumber,
          memberSalary: el.memberSalary,
          memberNumber: el.memberNumber,
          memberContribution: Math.round(((this.memberPercent / 100) * el.memberSalary)),
          employerContribution: Math.round(((this.employerPercent / 100) * el.memberSalary)),
          compensation: 0,
          amountContributed: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary)),
          amountContributedG: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary)), 
        });
      }
    }
    else 
    {
  
    this.UpdatedArray = JSON.parse(sessionStorage.getItem("employeeUploadListDataSource"))
    //
    this.employeeUploadListDataSource.splice(0)
    let counter = 0;
    for (const el of this.UpdatedArray) {
      this.lastContributionTotalAmount = +((this.memberPercent / 100) * el.BaseAmount) + ((this.employerPercent / 100) * el.BaseAmount);
      this.employeeUploadListDataSource.push({
        id: ++counter,
        correctName: el.correctName,
        isMemberNumberValid: el.isMemberNumberValid,
        isMemberNamesValid: el.isMemberNamesValid,
        memberNames: el.memberNames,
        employerNumber: el.employerNumber,
        memberSalary: el.memberSalary,
        memberNumber: el.memberNumber,
        memberContribution: Math.round(((this.memberPercent / 100) * el.memberSalary)),
        employerContribution: Math.round(((this.employerPercent / 100) * el.memberSalary)),
        compensation: Math.round(((this.compensationPercent / 100) * el.memberSalary)),
        amountContributed: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary)),
        amountContributedG: Math.round(((this.memberPercent / 100) * el.memberSalary) + ((this.employerPercent / 100) * el.memberSalary) + ((this.compensationPercent / 100) * el.memberSalary)), 
      });
    }
  
    }

    this.refreshSummary()
   

 if (this.selectedContributionMonths.length > 0) {
  // tslint:disable-next-line: max-line-length

  if(`${this.userGroup}`.match('INDIVIDUAL_CONTRIBUTOR')) {
    this.descriptionString = `Monthly individual contribution for ${this.accountName}, for ${this.monthInText.join(', ')} ${this.selectedContributionYear}.`;
  } else {
    this.descriptionString = `Monthly ${this.getContributionTypeName(e.value)} for ${this.accountName}, for ${this.monthInText.join(', ')} ${this.selectedContributionYear}.`;
  }

  this.invoiceForm.get('description').patchValue(this.descriptionString);
 }
  }

  getContributionTypeName(id) {
    let text = '';
    for (const iterator of this.contributionType) {
      if (iterator.id === id) {
        text = iterator.text;
        break;
      }
    }
    return text;
  }

  contributionInWords(month) {
    if (month == '1') {
      return 'January';
    } else if (month == '2') {
      return 'February';
    } else if (month == '3') {
      return 'March';
    } else if (month == '4') {
      return 'April';
    } else if (month == '5') {
      return 'May';
    } else if (month == '6') {
      return 'June';
    } else if (month == '7') {
      return 'July';
    } else if (month == '8') {
      return 'August';
    } else if (month == '9') {
      return 'September';
    } else if (month == '10') {
      return 'October';
    } else if (month == '11') {
      return 'November';
    } else if (month == '12') {
      return 'December';
    }
  }

   getUncontributedPeriods() {
    const data = {
      requestType: 'UNCONTRIBUTED_PERIODS',
      userType: this.authService.getUserDetails().userGroup,
      linkId: this.authService.getUserDetails().linkId
    };

    this.spinner.show();
    this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
      const serverResponse = res.json();
      if (serverResponse.code == 2000) {
        this.customerDetails.push({
        customerId: serverResponse.data.customerId,
        customerNo: serverResponse.data.customerNo,
        customerType: serverResponse.data.customerType,
        customerName: serverResponse.data.customerName,
        });
        this.uncontributedPeriod = serverResponse.data.uncontributedPeriods;
        let counter = 0;
          for (const iterator of serverResponse.data.uncontributedPeriods) {
            this.yearsMonths.push({
              id: ++counter,
              contributionMonth: this.contributionInWord(`${iterator.ContributingPeriod}`.substring(4)),
              contributionYear:  `${iterator.ContributingPeriod}`.substring(0, 4),
              description: `There is Uncontributed contribution for ${this.contributionInWord(`${iterator.ContributingPeriod}`.substring(4))} ${`${iterator.ContributingPeriod}`.substring(0, 4)}, please consider submitting or make payment (if you had already submitted a contribution but no payment was submitted until now) for the stated contribution first to clear this Contribution Gap.`
            });
          }
        if (this.yearsMonths.length > 0) {
          // if (this.yearsMonths.length > 1) {
            this.contributionMonthGapMessage = this.yearsMonths[this.yearsMonths.length - 1].description;
            // 
          // } else {
          //   this.contributionMonthGapMessage = this.yearsMonths[0].description;
          // }
          this.hideContributionMonthGap = false;
        } else {
          this.hideContributionMonthGap = true;
        }
        this.spinner.hide();
      } else {
       this.toastr.error(serverResponse.message);
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }
  contributionInWord(month) {
    if (month == '01' || month == '1') {
      return 'January';
    } else if (month == '02' || month == '2') {
      return 'February';
    } else if (month == '03' || month == '3') {
      return 'March';
    } else if (month == '04' || month == '4') {
      return 'April';
    } else if (month == '05' || month == '5') {
      return 'May';
    } else if (month == '06' || month == '6') {
      return 'June';
    } else if (month == '07' || month == '7') {
      return 'July';
    } else if (month == '08' || month == '8') {
      return 'August';
    } else if (month == '09' || month == '9') {
      return 'September';
    } else if (month == '10' || month == '10') {
      return 'October';
    } else if (month == '11' || month == '11') {
      return 'November';
    } else if (month == '12' || month == '12') {
      return 'December';
    }
}

// upload excel(xlsx) file

parseXLXS = (event) => {
  //
  this.employeeUploadListDataSource = [];
  let totalContributionsAmt = 0;
  this.uploadFailedMessage = 'Loading....';
  let reader = new FileReader();

  reader.onload = () => {
    let data = reader.result;
    let workbook = XLSX.read(data, {
      type: 'binary'
    });

    this.hideInfoAlert = false;
    // notify('Please wait while system verify the correctness of the information provided.', 'info', 6000);
    workbook.SheetNames.forEach((sheetName) => {
      let XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
      let json = XL_row_object;
      this.uploadFailedMessage =
      'Converting excel data format to json format....';
    this.spinner.show();
    let memberSalaryConverted: any;
    
    let counter = 0;
    // tslint:disable-next-line: prefer-for-of


    let validate = true;

    if(json.length > 1000) {
      validate = false;
      this.showLoadingDialog = false;
      this.spinner.hide();
    } else {
      //this.spinner.show();
      this.showLoadingDialog = true; //show data uploading dialog
    }

    for (let i = 0; i < json.length; i++) {
      if (!json[0].hasOwnProperty('memberNumber')) {
        // if (json.length < this.employeeUploadListDataSource.length) {
          this.showAlertDialog = true;
          this.alertReason = `Please include memberNumber header in your excelsheet on member numbers column.`;
          this.spinner.hide();
          return;
        // }
      }

      if (!json[0].hasOwnProperty('memberName')) {
        // if (json.length < this.employeeUploadListDataSource.length) {
          this.showAlertDialog = true;
          this.alertReason = `Please include memberName header in your excelsheet on member names column.`;
          this.spinner.hide();
          return;
        // }
      }

      if (!json[0].hasOwnProperty('memberSalary')) {
        // if (json.length < this.employeeUploadListDataSource.length) {
          this.showAlertDialog = true;
          this.alertReason = `Please include memberSalary header in your excelsheet on members salary column.`;
          // this.toastr.error('Please include memberName header in your excelsheet for members names column..');
          this.spinner.hide();
          return;
        // }
      }

      if (json[i].memberSalary == null || json[i].memberSalary == '' || json[i].memberSalary === undefined) {
        if (json.length <= this.employeeUploadListDataSource.length) {
          this.showAlertDialog = true;
          this.alertReason = `Member Salary is Missing, please update your member\'s salary in the excel sheet.`;
          // this.toastr.error('Member Salary is required, please update your member\'s salary in the excel sheet 2.');
          this.spinner.hide();
          return;
         }
      }

      if (json[i].memberName == null || json[i].memberName == '' || json[i].memberName == undefined) {
        if (json.length <= this.employeeUploadListDataSource.length) {
          this.showAlertDialog = true;
          this.alertReason = `MemberName column in your excelsheet is empty, please include it in your excelsheet.`;
          // this.toastr.error('Member Name is required, please include it in your excelsheet.');
          this.spinner.hide();
          return;
        }
      }


      if (isNaN(json[i].memberSalary) && json[i].memberSalary !== undefined && json[i].memberSalary != '') {
        memberSalaryConverted = json[i].memberSalary.toString();
        
        memberSalaryConverted = +memberSalaryConverted.replace(/,/g, '');
      } else {
        memberSalaryConverted = json[i].memberSalary;
      }
      if (!isNaN(memberSalaryConverted)) {
      if (memberSalaryConverted < 0) {
        // this.toastr.error(`Negative amount is not allowed for members salaries.`);
        
        this.showAlertDialog = true;
        this.alertReason = `Negative amount is not allowed for members salaries.`;
        this.spinner.hide();
        return;
      }
    }

    if (json.length < this.employeeUploadListDataSource.length) {
      if (!isNaN(memberSalaryConverted)) {
      if (memberSalaryConverted < this.thresholdAmount && this.invoiceForm.get("currency").value == 'TZS') {
        this.showAlertDialog = true;
        // tslint:disable-next-line: max-line-length
        this.alertReason = `One of your member has salary below the threshold amount (${this.thresholdAmount}), current entries has been filtered out to allow only members with salary above threshold amount (${this.thresholdAmount}). please update the your member salary in the excel sheet.`;
        this.spinner.hide();
        return;
      }
    }
  }

      let memberNumber = json[i].memberNumber === undefined ? '':`${json[i].memberNumber}`;
      const memberNames = json[i].memberName;

      if (memberNumber.trim() === '' || memberNumber === null || memberNumber === undefined) {
        memberNumber = ""
      }

      if (`${memberNames}`.trim() === '' || memberNames === null || memberNames === undefined) {
        this.isMemberNamesMatch = false;
      }

    //   const data =  {
    //     requestType: "MEMBERS_VERIFY",
    //     members: [
    //         {
    //             memberNumber,
    //             memberNames
    //         }
    //     ]
    // };

    //

    if (memberSalaryConverted >=  this.thresholdAmount) {
          
      if(this.contributionYear < 2023  ){
      
         this.employeeUploadListDataSource.push({
          id: ++counter,
          isMemberNumberValid: true,
          isMemberNamesValid: true,
          correctName: '',
          employerNumber: this.currrentEmployerNumber,
          memberNames: json[i].memberName,
          memberSalary: memberSalaryConverted,
          memberNumber: memberNumber == 'undefined' ? '':memberNumber,
          memberContribution: Math.round(((this.memberPercent / 100) * memberSalaryConverted)),
          employerContribution: Math.round(((this.employerPercent / 100) * memberSalaryConverted)),
          compensation: 0,
          amountContributedG: Math.round(((this.memberPercent / 100) * memberSalaryConverted) + ((this.employerPercent / 100) * memberSalaryConverted)),   
          amountContributed:
          Math.round((((this.memberPercent / 100) * memberSalaryConverted) +
            ((this.employerPercent / 100) * memberSalaryConverted) 
            )),
          
        });
        totalContributionsAmt +=
        Math.round((((this.memberPercent / 100) * +memberSalaryConverted) +
          ((this.employerPercent / 100) * memberSalaryConverted) 
          ));
      }

      else if(this.contributionYear < 2023 && this.isEmployerGov == true) {
        this.employeeUploadListDataSource.push({
          id: ++counter,
          isMemberNumberValid: true,
          isMemberNamesValid: true,
          correctName: '',
          employerNumber: this.currrentEmployerNumber,
          memberNames: json[i].memberName,
          memberSalary: memberSalaryConverted,
          memberNumber: memberNumber == 'undefined' ? '':memberNumber,
          memberContribution: Math.round(((this.memberPercent / 100) * memberSalaryConverted)),
          employerContribution: Math.round(((this.employerPercent / 100) * memberSalaryConverted)),
          compensation: 0,
          amountContributedG: Math.round(((this.memberPercent / 100) * memberSalaryConverted) + ((this.employerPercent / 100) * memberSalaryConverted)),   
          amountContributed:
          Math.round((((this.memberPercent / 100) * memberSalaryConverted) +
            ((this.employerPercent / 100) * memberSalaryConverted) 
            )),
          
        });
        totalContributionsAmt +=
        Math.round((((this.memberPercent / 100) * +memberSalaryConverted) +
          ((this.employerPercent / 100) * memberSalaryConverted) 
          ));
      }
      
      
      else if(this.contributionYear == 2023 && this.isEmployerGov == true && this.compensationZeroGov == true && this.contributionTypeValue == 4 ){
        this.employeeUploadListDataSource.push({
          id: ++counter,
          isMemberNumberValid: true,
          isMemberNamesValid: true,
          correctName: '',
          employerNumber: this.currrentEmployerNumber,
          memberNames: json[i].memberName,
          memberSalary: memberSalaryConverted,
          memberNumber: memberNumber == 'undefined' ? '':memberNumber,
          memberContribution: Math.round(((this.memberPercent / 100) * memberSalaryConverted)),
          employerContribution: Math.round(((this.employerPercent / 100) * memberSalaryConverted)),
          compensation: 0,
          amountContributedG: Math.round(((this.memberPercent / 100) * memberSalaryConverted) + ((this.employerPercent / 100) * memberSalaryConverted)),   
          amountContributed:
          Math.round((((this.memberPercent / 100) * memberSalaryConverted) +
            ((this.employerPercent / 100) * memberSalaryConverted) 
            )),
          
        });
        totalContributionsAmt +=
        Math.round((((this.memberPercent / 100) * +memberSalaryConverted) +
          ((this.employerPercent / 100) * memberSalaryConverted) 
          ));
      }

      else if(this.contributionYear >= 2023 && this.contributionTypeValue == 4 )
      {  
       
        this.employeeUploadListDataSource.push({
          id: ++counter,
          isMemberNumberValid: true,
          isMemberNamesValid: true,
          correctName: '',
          employerNumber: this.currrentEmployerNumber,
          memberNames: json[i].memberName,
          memberSalary: memberSalaryConverted,
          memberNumber: memberNumber == 'undefined' ? '':memberNumber,
          memberContribution: 0,
          employerContribution: 0,
          compensation: Math.round(((this.compensationPercent / 100) * memberSalaryConverted)), 
          //amountContributed:(((this.compensationPercent / 100) * memberSalaryConverted)),
          amountContributed:0,
          amountContributedG: Math.round(((this.compensationPercent / 100) * memberSalaryConverted)),  
        });
        totalContributionsAmt +=Math.round((((this.compensationPercent / 100) * memberSalaryConverted)))
        }

        else if(this.contributionYear == 2023 && this.isEmployerGov == true && this.compensationZeroGov == true )
        {
         
          this.employeeUploadListDataSource.push({
            id: ++counter,
            isMemberNumberValid: true,
            isMemberNamesValid: true,
            correctName: '',
            employerNumber: this.currrentEmployerNumber,
            memberNames: json[i].memberName,
            memberSalary: memberSalaryConverted,
            memberNumber: memberNumber == 'undefined' ? '':memberNumber,
            memberContribution: Math.round(((this.memberPercent / 100) * memberSalaryConverted)),
            employerContribution: Math.round(((this.employerPercent / 100) * memberSalaryConverted)),
            compensation: 0,
            amountContributedG: Math.round(((this.memberPercent / 100) * memberSalaryConverted) + ((this.employerPercent / 100) * memberSalaryConverted)),   
            amountContributed:
            Math.round((((this.memberPercent / 100) * memberSalaryConverted) +
              ((this.employerPercent / 100) * memberSalaryConverted) 
              )),
            
          });
          totalContributionsAmt +=
          Math.round((((this.memberPercent / 100) * +memberSalaryConverted) +
            ((this.employerPercent / 100) * memberSalaryConverted) 
            ));

           // this.compensationZeroGov = false;
        }
       
       else{
        
        this.employeeUploadListDataSource.push({
          id: ++counter,
          isMemberNumberValid: true,
          isMemberNamesValid: true,
          correctName: '',
          employerNumber: this.currrentEmployerNumber,
          memberNames: json[i].memberName,
          memberSalary: memberSalaryConverted,
          memberNumber: memberNumber == 'undefined' ? '':memberNumber,
          memberContribution: Math.round(((this.memberPercent / 100) * memberSalaryConverted)),
          employerContribution: Math.round(((this.employerPercent / 100) * memberSalaryConverted)),
          compensation: Math.round(((this.compensationPercent / 100) * memberSalaryConverted)), 
          amountContributedG: Math.round(((this.memberPercent / 100) * memberSalaryConverted) + ((this.employerPercent / 100) * memberSalaryConverted) + ((this.compensationPercent / 100) * memberSalaryConverted)),  
          amountContributed:
          Math.round((((this.memberPercent / 100) * memberSalaryConverted) +
            ((this.employerPercent / 100) * memberSalaryConverted))
            // ((this.compensationPercent / 100) * memberSalaryConverted)
            ),
          
        });
        totalContributionsAmt +=
        Math.round((((this.memberPercent / 100) * +memberSalaryConverted) +
          ((this.employerPercent / 100) * memberSalaryConverted) +
          ((this.compensationPercent / 100) * memberSalaryConverted))
          );
      }


      
      
     


        if (`${memberNames}`.trim() === '' || memberNames === null || memberNames === undefined) {
          this.isMemberNamesMatch = false;
        }

        const data =  {
          requestType: "MEMBERS_VERIFY",
          members: [
              {
                  memberNumber,
                  memberNames
              }
          ]
      };

      if (validate) {
        this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
          this.hideInfoAlert = false;
          const serverRes = res.json();
          if (serverRes.code == 2000) {
            this.totalMembersWithInvalidNumbers = 0;
            this.totalMembersWithNameMismatch = 0;
             for (const el of serverRes.data) {
               if (el.status == 2015) {  // invalid member number
                this.isMemberNumberValid = false;
                this.isMemberNamesMatch = false;
                this.employeeUploadListDataSource[i].isMemberNumberValid = false;
                this.employeeUploadListDataSource[i].isMemberNamesValid = false;
                this.totalMembersWithInvalidNumbers += 1;
               }

               if (el.status == 2016) {  // Difference in names
                this.isMemberNamesMatch = false;
                this.isMemberNumberValid = true;
                this.employeeUploadListDataSource[i].isMemberNumberValid = true;
                this.employeeUploadListDataSource[i].isMemberNamesValid = false;
                this.employeeUploadListDataSource[i].correctName = `${el.registeredName}`;
                this.totalMembersWithNameMismatch += 1;
               }

               if (el.status == 2000) {  // Validation Successful / Empty Member Number
                this.isMemberNumberValid = true;
                this.isMemberNamesMatch = true;
                this.employeeUploadListDataSource[i].isMemberNumberValid = true;
                this.employeeUploadListDataSource[i].isMemberNamesValid = true;
                this.employeeUploadListDataSource[i].correctName = `${el.registeredName}`;
               }
             }
             this.updateInvalidConstraints();
               sessionStorage.removeItem("employeeUploadListDataSource")
              //
               sessionStorage.setItem("employeeUploadListDataSource",JSON.stringify(this.employeeUploadListDataSource))
          }
        }, err => {
         this.toastr.info("Something went wrong while validating the correctness of the information given, please make sure you have internet connection, or procced without validations.");
         this.isMemberNumberValid = true; 
         this.isMemberNamesMatch = true; 
        });
      }
    }
      // if (memberSalaryConverted >=  this.thresholdAmount) {

      //   if(this.contributionYear < 2023 && this.contributionTypeValue != 4)
      //   {
      //     this.employeeUploadListDataSource.push({
      //       id: ++counter,
      //       isMemberNumberValid: true,
      //       isMemberNamesValid: true,
      //       correctName: '',
      //       employerNumber: this.authService.getUserDetails().linkId,
      //       memberNames: json[i].memberName,
      //       memberSalary: memberSalaryConverted,
      //       memberNumber: memberNumber,
      //       memberContribution: ((this.memberPercent / 100) * memberSalaryConverted),
      //       employerContribution: ((this.employerPercent / 100) * memberSalaryConverted),
      //       compensation: 0,  
      //       amountContributed:
      //         (((this.memberPercent / 100) * memberSalaryConverted) +
      //         ((this.employerPercent / 100) * memberSalaryConverted) 
      //         ),
            
      //     });
      //     totalContributionsAmt +=
      //       (((this.memberPercent / 100) * +memberSalaryConverted) +
      //       ((this.employerPercent / 100) * memberSalaryConverted) 
      //       );
      //   }
      //   else if(this.contributionYear < 2023 && this.isEmployerGov == true){
      //     this.employeeUploadListDataSource.push({
      //       id: ++counter,
      //       isMemberNumberValid: true,
      //       isMemberNamesValid: true,
      //       correctName: '',
      //       employerNumber: this.authService.getUserDetails().linkId,
      //       memberNames: json[i].memberName,
      //       memberSalary: memberSalaryConverted,
      //       memberNumber: "",
      //       memberContribution: ((this.memberPercent / 100) * memberSalaryConverted),
      //       employerContribution: ((this.employerPercent / 100) * memberSalaryConverted),
      //       compensation: 0,  
      //       amountContributed:
      //         (((this.memberPercent / 100) * memberSalaryConverted) +
      //         ((this.employerPercent / 100) * memberSalaryConverted) 
      //         ),
            
      //     });
      //     totalContributionsAmt +=
      //       (((this.memberPercent / 100) * +memberSalaryConverted) +
      //       ((this.employerPercent / 100) * memberSalaryConverted) 
      //       );
      //   }

      //   else if(this.contributionYear < 2023 && this.contributionTypeValue == 4 && this.isEmployerGov == false){
      //     this.employeeUploadListDataSource.push({
      //       id: ++counter,
      //       isMemberNumberValid: true,
      //       isMemberNamesValid: true,
      //       correctName: '',
      //       employerNumber: this.authService.getUserDetails().linkId,
      //       memberNames: json[i].memberName,
      //       memberSalary: memberSalaryConverted,
      //       memberNumber: "",
      //       memberContribution: 0,
      //       employerContribution: 0,
      //       compensation: ((this.compensationPercent / 100) * memberSalaryConverted), 
      //       amountContributed:(((this.compensationPercent / 100) * memberSalaryConverted)),
            
      //     });
      //     totalContributionsAmt +=(((this.compensationPercent / 100) * memberSalaryConverted))
      //   }

      //   else if(this.contributionYear == 2023 && this.isEmployerGov == true && this.compensationZeroGov == true )
      //   {
      //     this.employeeUploadListDataSource.push({
      //       id: ++counter,
      //       isMemberNumberValid: true,
      //       isMemberNamesValid: true,
      //       correctName: '',
      //       employerNumber: this.authService.getUserDetails().linkId,
      //       memberNames: json[i].memberName,
      //       memberSalary: memberSalaryConverted,
      //       memberNumber: "",
      //       memberContribution: ((this.memberPercent / 100) * memberSalaryConverted),
      //       employerContribution: ((this.employerPercent / 100) * memberSalaryConverted),
      //       compensation: 0,  
      //       amountContributed:
      //         (((this.memberPercent / 100) * memberSalaryConverted) +
      //         ((this.employerPercent / 100) * memberSalaryConverted) 
      //         ),
            
      //     });
      //     totalContributionsAmt +=
      //       (((this.memberPercent / 100) * +memberSalaryConverted) +
      //       ((this.employerPercent / 100) * memberSalaryConverted) 
      //       );
      //   }

      //   else{
      //     this.employeeUploadListDataSource.push({
      //       id: ++counter,
      //       isMemberNumberValid: true,
      //       isMemberNamesValid: true,
      //       correctName: '',
      //       employerNumber: this.authService.getUserDetails().linkId,
      //       memberNames: json[i].memberName,
      //       memberSalary: memberSalaryConverted,
      //       memberNumber: "",
      //       memberContribution: ((this.memberPercent / 100) * memberSalaryConverted),
      //       employerContribution: ((this.employerPercent / 100) * memberSalaryConverted),
      //       compensation: ((this.compensationPercent / 100) * memberSalaryConverted),  
      //       amountContributed:
      //         (((this.memberPercent / 100) * memberSalaryConverted) +
      //         ((this.employerPercent / 100) * memberSalaryConverted) +
      //         ((this.compensationPercent / 100) * memberSalaryConverted)
      //         ),
            
      //     });
      //     totalContributionsAmt +=
      //       (((this.memberPercent / 100) * +memberSalaryConverted) +
      //       ((this.employerPercent / 100) * memberSalaryConverted) +
      //       ((this.compensationPercent / 100) * memberSalaryConverted)
      //       );
      //   }
        
        

      //     let memberNumber = `${json[i].memberNumber}`;
      //     const memberNames = json[i].memberName;

      //     if (memberNumber.trim() === '' || memberNumber === null || memberNumber === undefined) {
      //       memberNumber = ""
      //     }

      //     if (`${memberNames}`.trim() === '' || memberNames === null || memberNames === undefined) {
      //       this.isMemberNamesMatch = false;
      //     }

      //     const data =  {
      //       requestType: "MEMBERS_VERIFY",
      //       members: [
      //           {
      //               memberNumber,
      //               memberNames
      //           }
      //       ]
      //   };

        // if (validate) {
        //   this.utilities.postServiceCall(data, 'portal/request').subscribe(res => {
        //     this.hideInfoAlert = false;
        //     const serverRes = res.json();
        //     if (serverRes.code == 2000) {
        //       this.totalMembersWithInvalidNumbers = 0;
        //       this.totalMembersWithNameMismatch = 0;
        //        for (const el of serverRes.data) {
        //          if (el.status == 2015) {  // invalid member number
        //           this.isMemberNumberValid = false;
        //           this.isMemberNamesMatch = false;
        //           this.employeeUploadListDataSource[i].isMemberNumberValid = false;
        //           this.employeeUploadListDataSource[i].isMemberNamesValid = false;
        //           this.totalMembersWithInvalidNumbers += 1;
        //          }

        //          if (el.status == 2016) {  // Difference in names
        //           this.isMemberNamesMatch = false;
        //           this.isMemberNumberValid = true;
        //           this.employeeUploadListDataSource[i].isMemberNumberValid = true;
        //           this.employeeUploadListDataSource[i].isMemberNamesValid = false;
        //           this.employeeUploadListDataSource[i].correctName = `${el.registeredName}`;
        //           this.totalMembersWithNameMismatch += 1;
        //          }

        //          if (el.status == 2000) {  // Validation Successful / Empty Member Number
        //           this.isMemberNumberValid = true;
        //           this.isMemberNamesMatch = true;
        //           this.employeeUploadListDataSource[i].isMemberNumberValid = true;
        //           this.employeeUploadListDataSource[i].isMemberNamesValid = true;
        //           this.employeeUploadListDataSource[i].correctName = `${el.registeredName}`;
        //          }
        //        }
        //        this.updateInvalidConstraints();
        //     }
        //   }, err => {
        //    this.toastr.info("Something went wrong while validating the correctness of the information given, please make sure you have internet connection, or procced without validations.");
        //    this.isMemberNumberValid = true;
        //    this.isMemberNamesMatch = true;
        //   });
        // }
      // }
      // this.employeeUploadListDataSource = json;      
      this.uploadFailedMessage = 'Conversion completed....';
      this.showLoadingDialog = true;
    }
    })
  };

  reader.onloadend = () => {
    this.spinner.hide();
    this.uploadFailedMessage = 'Data uploaded successfully....';

    setTimeout(() => {
      this.showLoadingDialog = false
    }, 7000);
    //this.showLoadingDialog = false
    this.openFileUploadDialog = false;
    this.notifyDataSourceChanges();
  };

  reader.onerror = (ex) => {
  };
  
  reader.readAsBinaryString(event.file);
  this.hideEmployeeUploadForm = true;
  this.hideEmployeeListDataGrid = false;
};
 
//  ExcelToJSON = function() {
//   this.parseExcel = function(file) {
//     var reader = new FileReader();

//     reader.onload = function(e) {
//       var data = e.target.result;
//       var workbook = XLSX.read(data, {
//         type: 'binary'
//       });

//       workbook.SheetNames.forEach(function(sheetName) {
//         // Here is your object
//         var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
//         var json_object = JSON.stringify(XL_row_object);

//       })

//     };

//     reader.onerror = function(ex) {
//     };

//     reader.readAsBinaryString(file);
//   };
// };

}


// BACKUP
// this.spinner.show();
// const data = {
//   requestType : 'LAST_CONTRIBUTION',
//   linkId: this.authService.getUserDetails().linkId
// };
// this.lastContributionTotalAmount = 0;
// this.utilities
//   .postServiceCall(data, 'portal/request')
//   .subscribe(
//     res => {
//       this.employeeUploadListDataSource = [];
//       const serverRes = res.json();
//       this.spinner.hide();
//       this.hideEmployeeListDataGrid = false;
//       if (serverRes.code === 2000) {
//         this.toastr.success(serverRes.message);
//         // memberId: el.memberId,
//         let counter = 0;
//         for (const el of serverRes.data) {
//           this.lastContributionTotalAmount += el.amountContributed;
//           this.employeeUploadListDataSource.push({
//             id: ++counter,
//             memberNames: `${el.firstName} ${el.middleName} ${el.surName}`,
//             employerNumber: this.authService.getUserDetails().linkId,
//             memberSalary: el.salaryBeforeTax,
//             memberNumber: el.memberNumber,
//             memberContribution: ((this.memberPercent / 100) * el.salaryBeforeTax),
//             employerContribution: ((this.employerPercent / 100) * el.salaryBeforeTax),
//             amountContributed: ((this.memberPercent / 100) * el.salaryBeforeTax) + ((this.employerPercent / 100) * el.salaryBeforeTax)
//           });
//         }
//       } else {
//         this.toastr.error(serverRes.message);
//       }
//     },
//     (error) => {
//       this.spinner.hide();
//       this.toastr.error('Something went wrong, Please try again.');
//     }
//   );

// Backup codes
/*
  loadSelectedContributionMonth(e) {
    this.spinner.show();
    const data = {
      requestType: 'INVOICE_CONTRIBUTIONS',
      invoiceId: e.InvoiceID
    };
    this.lastContributionTotalAmount = 0;
    this.utilities
      .postServiceCall(data, 'portal/request')
      .subscribe(
        res => {
          this.employeeUploadListDataSource = [];
          const serverRes = res.json();
          this.hideEmployeeListDataGrid = false;
          if (serverRes.code === 2000) {
            this.toastr.success(serverRes.message);
            // memberId: el.memberId,
            let counter = 0;

            for (const el of serverRes.data) {
              this.lastContributionTotalAmount = +el.AmountContributed;
              this.employeeUploadListDataSource.push({
                id: ++counter,
                memberNames: `${el.FirstName} ${el.MiddleName} ${el.SurName}`,
                employerNumber: this.authService.getUserDetails().linkId,
                memberSalary: el.i[0].BaseAmount,
                memberNumber: el.MemberNumber,
                memberContribution: ((this.memberPercent / 100) * el.i[0].BaseAmount),
                employerContribution: ((this.employerPercent / 100) * el.i[0].BaseAmount),
                amountContributed: ((this.memberPercent / 100) * el.i[0].BaseAmount) + ((this.employerPercent / 100) * el.i[0].BaseAmount)
              });
            }
            this.spinner.hide();
            // do sorting
            // this.employeeUploadListDataSource = this.employeeUploadListDataSource.sort(this.funCompare)
            this.showDialog = false;
          } else {
            this.toastr.error(serverRes.message);
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error('Something went wrong, Please try again.');
        }
      );
  }

  onLoadExistingEmployeesList() {
    this.spinner.show();
    let memberType = 'EMPLOYER';
    if (this.authService.getUserDetails().userGroup === 'EMPLOYER' ) {
      memberType = 'EMPLOYER';
    }

    if (this.authService.getUserDetails().userGroup === 'INDIVIDUAL_CONTRIBUTOR') {
      memberType = 'INDIVIDUAL_CONTRIBUTOR';
    }

    const tps = {
      requestType: 'LAST_CONTRIBUTIONS',
      linkId: this.currrentEmployerNumber,
      memberType: memberType
    }
    this.utilities.postServiceCall(tps, 'portal/request').subscribe(res => {
      const serverRes = res.json();
      this.spinner.hide();
      if (serverRes.code == 2000) {
      this.lastContributionsDataSet = [];
      let counter = 0;
      for (const iterator of serverRes.data) {
        this.lastContributionsDataSet.push({
          id: ++counter,
          ContributionYear: iterator.ContributionYear,
          ContributionMonth: this.contributionInWords(iterator.ContributionMonth) ,
          ContributingPeriod: iterator.ContributingPeriod,
          Narration: iterator.Narration,
          InvoiceID: iterator.InvoiceID,
          InvoiceAmount: iterator.i[0].InvoiceAmount,
          currency: 'TZS'
        });
      }
      this.showDialog = true;
      }
    }, err => {
      this.spinner.hide();
      this.toastr.error('Something went wrong, please try again later.');
    });
  }
*/
